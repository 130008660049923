import styled from "styled-components";

const DescText = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  letter-spacing: 0.02em;
  color: #17202a;
`;

type TDescriptionTextProps = {
  text: string;
};

const DescriptionText = ({ text }: TDescriptionTextProps) => {
  return <DescText>{text}</DescText>;
};

export default DescriptionText;
