import IconLogout from "components/Icon/Logout";
import { getUserInfo } from "helpers/authCookies";
import { AuthContext } from "provider/AuthProvider";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MENU, SUPER_MENU } from "./menu";
import { useTranslation } from "react-i18next";
import { Drawer, DrawerProps, Menu } from "antd";
import Logo from "components/Icon/Logo";

const DrawerMenu = (props: DrawerProps) => {
  const { t } = useTranslation("translation");
  const location = useLocation();
  const navigate = useNavigate();
  const { logOut } = useContext(AuthContext);

  const userInfo = getUserInfo();
  const navigateToMenuPath = (path: string) => {
    navigate({
      pathname: path,
    });
  };

  return (
    <>
      <Drawer title={<Logo />} placement={"left"} {...props}>
        <Menu
          defaultSelectedKeys={["1"]}
          style={{
            gap: "16px",
            padding: "14px 14px 14px 24px",
            fontWeight: "600",
            fontSize: "18px",
            lineHeight: "23px",
            letterSpacing: "0.02em",
            color: "#828282",
            cursor: "pointer",
          }}>
          {(userInfo?.role as string) === "Admin"
            ? MENU.map((item, index) => (
                <>
                  <Menu.Item
                    style={{
                      width: "100px",
                      overflow: "visible",
                      height: "66px",
                    }}
                    key={index}
                    className={
                      location.pathname.includes(item.path) ? "active" : ""
                    }
                    onClick={() => navigateToMenuPath(item.path)}>
                    <div style={{ width: "28px", height: "28px" }}>
                      <item.Icon />
                    </div>
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "18px",
                        lineHeight: "23px",
                        letterSpacing: "0.02em",
                        color: "#828282",
                        cursor: "pointer",
                      }}>
                      {t(item.name)}
                    </div>
                  </Menu.Item>
                </>
              ))
            : SUPER_MENU.map((item, index) => (
                <>
                  <Menu.Item
                    style={{
                      width: "100px",
                      overflow: "visible",
                      height: "66px",
                    }}
                    key={index}
                    className={
                      location.pathname.includes(item.path) ? "active" : ""
                    }
                    onClick={() => navigateToMenuPath(item.path)}>
                    <div style={{ width: "28px", height: "28px" }}>
                      <item.Icon />
                    </div>
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "18px",
                        lineHeight: "23px",
                        letterSpacing: "0.02em",
                        color: "#828282",
                        cursor: "pointer",
                      }}>
                      {t(item.name)}
                    </div>
                  </Menu.Item>
                </>
              ))}
          <Menu.Item
            onClick={logOut}
            style={{
              width: "100px",
              overflow: "visible",
              height: "66px",
            }}>
            <div style={{ width: "28px", height: "28px" }}>
              <IconLogout />
            </div>
            <div
              style={{
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "23px",
                letterSpacing: "0.02em",
                color: "#828282",
                cursor: "pointer",
              }}>
              Log out
            </div>
          </Menu.Item>
        </Menu>
      </Drawer>
    </>
  );
};

export default DrawerMenu;
