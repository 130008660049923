import {
  Card,
  Col,
  Descriptions,
  PageHeader,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import { getFreelancerDetail } from "api/freelancers";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  FaAddressCard,
  FaCcVisa,
  FaMoneyBillAlt,
  FaPassport,
  FaSchool,
  FaUserAlt,
} from "react-icons/fa";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { MdEmail, MdCastForEducation, MdPayment } from "react-icons/md";
import { paymentType } from "constants/paymentType";
import { BsCalendar2Date, BsFillTelephoneFill } from "react-icons/bs";
import { IoMdSchool } from "react-icons/io";
import moment from "moment";
import { GrOrganization, GrMapLocation } from "react-icons/gr";
import { GiPositionMarker } from "react-icons/gi";
import { AiOutlineCheck } from "react-icons/ai";

const FreelancerDetail = () => {
  const { t } = useTranslation("translation");
  const { id } = useParams();
  const { data, mutate } = useMutation(["getDetail"], getFreelancerDetail);

  useEffect(() => {
    mutate(id as string);
    // eslint-disable-next-line
  }, [id]);
  return (
    <div>
      <PageHeader
        title={`${t("freelancer_detail")}: ${data?.firstName} ${
          data?.lastName
        }`}
        onBack={() => window.history.back()}
        className="site-page-header-responsive"
        style={{ padding: 0 }}>
        <Card>
          <Row>
            <Col md={24} style={{ minHeight: 100 }}>
              <Card style={{ height: "100%" }}>
                <Descriptions
                  size="small"
                  column={3}
                  title={t("Personal_Details")}
                  layout="vertical"
                  colon={false}>
                  <Descriptions.Item
                    label={
                      <Row>
                        <Space>
                          <div style={{ marginTop: 5 }}>
                            <FaUserAlt size="28" />
                          </div>
                          <h2>{t("FirstName")}</h2>
                        </Space>
                      </Row>
                    }>
                    {data?.firstName || "-"}
                  </Descriptions.Item>

                  <Descriptions.Item
                    label={
                      <Row>
                        <Space>
                          <div style={{ marginTop: 5 }}>
                            <FaUserAlt size="28" />
                          </div>
                          <h2>{t("LastName")}</h2>
                        </Space>
                      </Row>
                    }>
                    {data?.lastName || "-"}
                  </Descriptions.Item>

                  <Descriptions.Item
                    label={
                      <Row>
                        <Space>
                          <div style={{ marginTop: 5 }}>
                            <MdEmail size="28" />
                          </div>
                          <h2>{t("Email_email")}</h2>
                        </Space>
                      </Row>
                    }>
                    {data?.email || "-"}
                  </Descriptions.Item>
                </Descriptions>
                <Row className="mt-2">
                  <Col style={{ paddingRight: 2, marginTop: 15 }} span={8}>
                    <Row>
                      <Space>
                        <div style={{ marginTop: 5 }}>
                          <BsFillTelephoneFill size="28" />
                        </div>
                        <h2>{t("Phone_phone")}</h2>
                      </Space>
                    </Row>
                    {data?.phone || "-"}
                  </Col>
                  <Col style={{ paddingRight: 2, marginTop: 15 }} span={8}>
                    <Row>
                      <Space>
                        <div style={{ marginTop: 5 }}>
                          <FaAddressCard size="28" />
                        </div>
                        <h3>
                          {t("state_or_Province")} / {t("City_city")} /{" "}
                          {t("Country")}
                        </h3>
                      </Space>
                    </Row>
                    {data?.personalDetail?.state || "-"} / {data?.personalDetail?.city || "-"} / {data?.personalDetail?.country || "-"}
                  </Col>
                  <Col style={{ paddingRight: 2, marginTop: 15 }} span={8}>
                    <Row>
                      <Space>
                        <div style={{ marginTop: 5 }}>
                          <FaAddressCard size="28" />
                        </div>
                        <h3>
                          {t("House_number")} / {t("Other_address")}
                        </h3>
                      </Space>
                    </Row>
                    {data?.personalDetail?.houseNumber || "-"} / {data?.personalDetail?.otherAddress || "-"}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col span={12}>
                    <Col style={{ paddingRight: 2, marginTop: 15 }} span={24}>
                      <Row>
                        <Space>
                          <div style={{ marginTop: 5 }}>
                            <FaMoneyBillAlt size="28" />
                          </div>
                          <h2>{t("Expected_salary")}</h2>
                        </Space>
                      </Row>
                      {data?.otherDetail?.expectedSalary || "-"}
                    </Col>
                  </Col>
                  <Col span={12}>
                    <Col style={{ paddingRight: 2, marginTop: 15 }} span={24}>
                      <Row>
                        <Space>
                          <div style={{ marginTop: 5 }}>
                            <MdPayment size="28" />
                          </div>
                          <h2>{t("Payment_type")}</h2>
                        </Space>
                      </Row>
                      <Tag>
                        {paymentType.find(
                          (item) =>
                            item.id === data?.otherDetail?.paymentTypeId
                        )?.text || "-"}
                      </Tag>
                    </Col>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <Card>
                <Descriptions
                  size="small"
                  column={3}
                  title={t("edu_detail")}
                  layout="vertical"
                  colon={false}>
                  <Descriptions.Item
                    label={
                      <Row>
                        <Space>
                          <div style={{ marginTop: 5 }}>
                            <FaSchool size="28" />
                          </div>
                          <h2>{t("Name_name")}</h2>
                        </Space>
                      </Row>
                    }>
                    {data?.educationDetail?.school || "-"}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <Row>
                        <Space>
                          <div style={{ marginTop: 5 }}>
                            <IoMdSchool size="28" />
                          </div>
                          <h2>{t("degree_degree")}</h2>
                        </Space>
                      </Row>
                    }>
                    {data?.educationDetail?.degree || "-"}
                  </Descriptions.Item>

                  <Descriptions.Item
                    label={
                      <Row>
                        <Space>
                          <div style={{ marginTop: 5 }}>
                            <MdCastForEducation size="28" />
                          </div>
                          <h2>{t("Field_of_study")}</h2>
                        </Space>
                      </Row>
                    }>
                    {data?.educationDetail?.fieldOfStudy || "-"}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <Row>
                        <Space>
                          <div style={{ marginTop: 5 }}>
                            <BsCalendar2Date size="28" />
                          </div>
                          <h2>{t("Start_Date")}</h2>
                        </Space>
                      </Row>
                    }>
                    {data?.educationDetail?.startDate
                      ? moment(data?.educationDetail?.startDate).format(
                          "YYYY-MM-DD"
                        )
                      : "-"}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <Row>
                        <Space>
                          <div style={{ marginTop: 5 }}>
                            <BsCalendar2Date size="28" />
                          </div>
                          <h2>{t("End_date")}</h2>
                        </Space>
                      </Row>
                    }>
                    {data?.educationDetail?.endDate
                      ? moment(data?.educationDetail?.endDate).format(
                          "YYYY-MM-DD"
                        )
                      : "-"}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <Card>
                <Typography.Title level={5} style={{ fontWeight: "bold" }}>
                  {t("WorkExperienceDetail")}
                </Typography.Title>
                {data?.workExperienceDetail?.map((work, index) => (
                  <Card key={index}>
                    <Descriptions
                      size="small"
                      column={3}
                      layout="vertical"
                      colon={false}>
                      <>
                        <Descriptions.Item
                          label={
                            <Row>
                              <Space>
                                <div style={{ marginTop: 5 }}>
                                  <GrOrganization size="28" />
                                </div>
                                <h2>{t("Company")}</h2>
                              </Space>
                            </Row>
                          }>
                          {work?.companyName || "-"}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={
                            <Row>
                              <Space>
                                <div style={{ marginTop: 5 }}>
                                  <GrMapLocation size="28" />
                                </div>
                                <h2>{t("Location_location")}</h2>
                              </Space>
                            </Row>
                          }>
                          {work?.location || "-"}
                        </Descriptions.Item>

                        <Descriptions.Item
                          label={
                            <Row>
                              <Space>
                                <div style={{ marginTop: 5 }}>
                                  <GrMapLocation size="28" />
                                </div>
                                <h2>{t("Country")}</h2>
                              </Space>
                            </Row>
                          }>
                          {work?.country || "-"}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={
                            <Row>
                              <Space>
                                <div style={{ marginTop: 5 }}>
                                  <GiPositionMarker size="28" />
                                </div>
                                <h2>{t("Position_position")}</h2>
                              </Space>
                            </Row>
                          }>
                          {work?.position || "-"}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={
                            <Row>
                              <Space>
                                <div style={{ marginTop: 5 }}>
                                  <BsCalendar2Date size="28" />
                                </div>
                                <h2>{t("Start_Date")}</h2>
                              </Space>
                            </Row>
                          }>
                          {work?.startDate
                            ? moment(work?.startDate).format("YYYY-MM-DD")
                            : "-"}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={
                            <Row>
                              <Space>
                                <div style={{ marginTop: 5 }}>
                                  <BsCalendar2Date size="28" />
                                </div>
                                <h2>{t("End_date")}</h2>
                              </Space>
                            </Row>
                          }>
                          {work?.endDate
                            ? moment(work?.endDate).format("YYYY-MM-DD")
                            : "-"}
                        </Descriptions.Item>
                      </>
                    </Descriptions>
                  </Card>
                ))}
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <Card>
                <Descriptions
                  size="small"
                  column={3}
                  title={t("OtherDetail")}
                  layout="vertical"
                  colon={false}>
                  <Descriptions.Item
                    label={
                      <Row>
                        <Space>
                          <div style={{ marginTop: 5 }}>
                            <FaPassport size="28" />
                          </div>
                          <h2>{t("PassportNumber")}</h2>
                        </Space>
                      </Row>
                    }>
                    {data?.otherDetail?.passportNumber || "-"}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <Row>
                        <Space>
                          <div style={{ marginTop: 5 }}>
                            <FaAddressCard size="28" />
                          </div>
                          <h2>{t("RecidentCard")}</h2>
                        </Space>
                      </Row>
                    }>
                    {data?.otherDetail?.residentCard || "-"}
                  </Descriptions.Item>

                  <Descriptions.Item
                    label={
                      <Row>
                        <Space>
                          <div style={{ marginTop: 5 }}>
                            <FaCcVisa size="28" />
                          </div>
                          <h2>{t("Visa_type")}</h2>
                        </Space>
                      </Row>
                    }>
                    {data?.otherDetail?.visaType || "-"}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <Row>
                        <Space>
                          <div style={{ marginTop: 5 }}>
                            <BsCalendar2Date size="28" />
                          </div>
                          <h2>{t("VisaDate")}</h2>
                        </Space>
                      </Row>
                    }>
                    {data?.otherDetail?.visaValidity
                      ? moment(data?.otherDetail?.visaValidity).format(
                          "YYYY-MM-DD"
                        )
                      : "-"}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <Row>
                        <Space>
                          <div style={{ marginTop: 5 }}>
                            <AiOutlineCheck size="28" />
                          </div>
                          <h2>{t("Applied_For")}</h2>
                        </Space>
                      </Row>
                    }>
                    {data?.otherDetail?.appliedFor || "-"}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <Row>
                        <Space>
                          <div style={{ marginTop: 5 }}>
                            <BsCalendar2Date size="28" />
                          </div>
                          <h2>{t("Application_Period")}</h2>
                        </Space>
                      </Row>
                    }>
                    {data?.otherDetail?.applicationPeriod || "-"}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <Card>
                <Descriptions
                  size="small"
                  column={1}
                  title={t("Skills")}
                  layout="vertical"
                  colon={false}>
                  <Descriptions.Item>
                    {data?.skills?.map((skill, index) => (
                      <>
                        <Tag key={index}>{skill?.name}</Tag>
                      </>
                    ))}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
          </Row>
        </Card>
      </PageHeader>
    </div>
  );
};

export default FreelancerDetail;
