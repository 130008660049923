import axios from "axios";

export interface IGetAdminCandidateFavoriteRes {
  companyId: string;
  candidateId: string;
  companyName: string;
  companyEmail: string;
  candidateName: string;
  candidateEmail: string;
  favoritedDate: string;
  validity: string;
}

type TGetFavoriteCandidateParams = {
  sortOrder?: string;
  sortBy?: string;
  keyword?: string;
};

export const getAdminCandidateFavorite = async (
  params: TGetFavoriteCandidateParams
) => {
  return (await axios.get(`/api/admin/Favorites/Candidates`, { params }))
    .data as IGetAdminCandidateFavoriteRes[];
};

export interface IGetFavoriteCandidateConfigRes {
  id: string;
  isActive: boolean;
  isFirstNameVisible: boolean;
  isLastNameVisible: boolean;
  isEmailVisible: boolean;
  isPhoneVisible: boolean;
  isPublicAddressVisible: boolean;
  isPrivateAddressVisible: boolean;
  isImageVisible?: boolean;
}

export const getAdminCandidateFavConfig = async (userId: string) => {
  return (
    await axios.get(`/api/admin/Favorites/GetCandidateConfig?id=${userId}`)
  ).data as IGetFavoriteCandidateConfigRes;
};

export const updateCandidateFavConfig = async (
  data: IGetFavoriteCandidateConfigRes
) => (await axios.put("/api/admin/Favorites/UpdateCandidateConfig", data)).data;

type TCandidateUpdateData = {
  isFirstNameVisible: boolean;
  isLastNameVisible: boolean;
  isCountryVisible: boolean;
  isStateVisible: boolean;
  isCityVisible: boolean;
  isPriceVisible: boolean;
  configurationId: string;
  isPrivateAddressVisible: boolean;
  isPublicAddressVisible: boolean;
};

export const updateCandidateConfig = async (data: TCandidateUpdateData) =>
  (await axios.put("/api/admin/Candidates/UpdateConfiguration", data)).data;

export interface PersonalDetail {
  firstName: string | null;
  lastName: string | null;
  email?: any | null;
  phone?: any | null;
  publicAddress: string | null;
  privateAddress?: any | null;
  expectedSalary: number | null;
  paymentTypeId: number | null;
  imageUrl?: any | null;
}

export interface EducationDetail {
  name: string | null;
  degree: string | null;
  fieldOfStudy: string | null;
  startDate: Date | null;
  endDate: Date | null;
}

export interface WorkExperienceDetail {
  company: string | null;
  location: string | null;
  country: string | null;
  position: string | null;
  startDate: Date | null;
  endDate?: any | null;
  description: string | null;
}

export interface OtherDetail {
  passportNumber: string | null;
  residentCard: string | null;
  visaType: string | null;
  visaValidity: Date | null;
  appliedFor: string | null;
  applicationPeriod: number | null;
}

export interface ICompanyCandidateFavoriteDetail {
  id: string;
  personalDetail: PersonalDetail;
  educationDetail: EducationDetail;
  workExperienceDetail: WorkExperienceDetail[];
  otherDetail: OtherDetail;
  skills: string[] | null;
  favoritedAt: Date | null;
  validity: Date | null;
}
export const getFavoriteCandidateDetail = async (candidateId: string) =>
  (await axios.get(`/api/Company/CandidateById?favId=${candidateId}`))
    .data as ICompanyCandidateFavoriteDetail;
