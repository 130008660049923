import styled from "styled-components";

export const LoginContainer = styled.div`
  min-height: calc(100vh - 151px);
  background: #003e59;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
  padding-top: 50px;
`;

export const FooterContainer = styled.div`
  footer {
    position: relative;
    background-color: #003e59;
  }
`;

export const LoginBox = styled.div`
  background: #ffffff;
  min-width: 320px;
  padding: 40px;
  text-align: center;

  @media screen and (min-width: 576px) {
    min-width: 468px;
  }
`;
