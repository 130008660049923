import { routes } from "constants/routes";
import { TUserStatus } from "provider/AuthProvider";
import React from "react";
import { Navigate } from "react-router-dom";

interface IProtectedRoutesProps {
  isLoggedIn: boolean;
  redirectPath: string;
  children: any;
  userStatus?: TUserStatus;
}

const ProtectedRoutes = ({ isLoggedIn, redirectPath = "/", children, userStatus }: IProtectedRoutesProps) => {
  if (!isLoggedIn) {
    return <Navigate to={redirectPath} replace />;
  }

  if (isLoggedIn && userStatus && userStatus === "Onboarding") return <Navigate to={routes.changePassword} />;

  return children;
};

export default React.memo(ProtectedRoutes);
