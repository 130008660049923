import axios from "axios";

export interface ICompaniesRes {
  email: string;
  id: string;
  industryTypes: [];
  location: string;
  name: string;
  phone: string;
  configuration: {
    favoriteCountLimit: number;
    favoritePeriodLimit: number;
    id: string;
    isCityVisible: boolean;
    isCountryVisible: boolean;
    isNameVisible: boolean;
  };
}

export interface CompanyTag {
  tagId: string;
  name: string;
}

export interface ICompanyProfileDetail {
  companyId: string;
  companyName: string;
  phone: string;
  tagIds?: any;
  website: string;
  country: string;
  state: string;
  city: string;
  otherAddress: string;
  houseNumber: string;
  companyTags: CompanyTag[];
}

type TGetCompaniesParams = {
  isAscending?: boolean;
  keyword?: string;
};

export const getCompanies = async (params: TGetCompaniesParams) =>
  (await axios.get("/api/admin/Companies", { params })).data as ICompaniesRes[];

export const getCompanyDetail = async (companyId: string) =>
  (await axios.get(`/api/company/getFullProfile?companyId=${companyId}`))
    .data as ICompanyProfileDetail;
