import axios from "axios";

interface IAdminRegisterData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export const registerAdmin = async (data: IAdminRegisterData) => (await axios.post("/api/admin/Admins", data)).data;

export interface IGetAdminsRes {
  currentStateId: number;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  phone: string;
}

type IGetAdminParams = {
  sortOrder?: string;
  keyword?: string;
};

export const getAdmins = async (params : IGetAdminParams) => (await axios.get("/api/admin/Admins", {params})).data as IGetAdminsRes[];

interface IAdminEditData {
  id: string;
  firstName: string;
  lastName: string;
}

export const editAdmin = async (data: IAdminEditData) => (await axios.put("/api/admin/Admins", data)).data;

export const deleteAdmin = async (id: string) => (await axios.delete(`/api/admin/Admins?id=${id}`)).data;

export const getAdminById = async (id: string) => (await axios.get(`/api/admin/Admins/${id}`)).data;

export const getAdminStatus = async (userId: string) => {
  return (await axios.get(`/api/admin/Admins/GetStatus?id=${userId}`)).data;
};

export interface IGetAdminProfileRes {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  currentStateId: number;
}

export const getAdminProfile = async (userId: string) => {
  return (await axios.get(`/api/admin/Admins/GetProfile?userId=${userId}`)).data as IGetAdminProfileRes;
};

interface IChangePasswordBody {
  email: string;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export const changeAdminPassword = async (data: IChangePasswordBody) => {
  return (await axios.put(`/api/admin/Admins/ChangePassword`, data)).data;
};
