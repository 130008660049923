export const routes = {
  login: "/",
  changePassword: "/change-password",
  termsOfService: "/terms-of-service",
  privacyPolicy: "/privacy-policy",
  cookiesSettings: "/cookies-settings",
  copyRightPolicy: "/copyright-policy",
  freelancers: "/freelancers",
  companies: "/companies",
  companyFavorite: "/company-favourite",
  companyFavoriteDetail: "/company-favourite/:id",
  pendingRequests: "/pending-requests",
  pendingRequestsDetail: "/pending-requests/:id",
  approvedConsultants: "/approved-consultants",
  registerAdmin: "/register-admin",
  registeredAdmin: "/registered-admin",
  jobFavorite: "/job-favourite",
  jobFavoriteDetail: "/job-favourite/:id",
  companyDetail: "/companies/:id",
  consultantDetail: "/approved-consultants/:id",
  freelancerDetail: "/freelancers/:id",
  jobsCreated: "/jobs-created",
  jobsCreatedDetail: "/jobs-created/:id",
};
