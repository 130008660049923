export const paymentType = [
  {
    id: 3,
    text: "Monthly",
  },
  {
    id: 1,
    text: "Hourly",
  },
  {
    id: 2,
    text: "Daily",
  },
  {
    id: 4,
    text: "Yearly",
  },
];
