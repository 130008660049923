import React from "react";

const IconCaretDown = () => {
  return (
    <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0L7 7L14 0H0Z" fill="currentColor" />
    </svg>
  );
};

export default IconCaretDown;
