import { Card } from "antd";
import styled from "styled-components";

export const ConfigCard = styled(Card)`
  height: 100%;
`;

export const ConfigCardTitle = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 39px;
  letter-spacing: 0.02em;
  color: #009bde;
`;

export const ConfigItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 12px;
  margin-bottom: 12px;
`;

export const ConfigItemText = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #17202a;
`;
