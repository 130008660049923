import { Form, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import { updateCandidateConfig } from "api/admin/candidate";
import { IFreelancersRes } from "api/freelancers";
import { ConfigCardTitle, ConfigItem } from "components/ConfigCard";
import { StyledButton } from "components/StyledButton";
import { StyledSwitch } from "components/StyledSwitch";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

interface IFeelanceActionProps {
  visible: boolean;
  onCancel: () => void;
  actionRecord?: IFreelancersRes;
  handleSuccess: () => void;
}

const FreelanceAction = ({
  visible,
  onCancel,
  actionRecord,
  handleSuccess,
}: IFeelanceActionProps) => {
  const { t } = useTranslation("translation");
  const { mutate, isLoading } = useMutation(updateCandidateConfig, {
    onSuccess: handleSuccess,
  });
  const [form] = useForm();

  const handleFinish = (values: any) => {
    mutate({
      ...values,
      configurationId: actionRecord?.configuration.id,
    });
  };

  useEffect(() => {
    if (actionRecord) {
      form.setFieldsValue(actionRecord.configuration);
    }
    // eslint-disable-next-line
  }, [actionRecord]);

  return (
    <Modal onCancel={onCancel} visible={visible} footer={null} title={null}>
      <ConfigCardTitle>{t("Manage_Details")}</ConfigCardTitle>
      <Form form={form} onFinish={handleFinish}>
        <ConfigItem>
          <div className="item-text">{t("FirstName")}</div>
          <Form.Item name="isFirstNameVisible" noStyle>
            <StyledSwitch
              defaultChecked={actionRecord?.configuration.isFirstNameVisible}
            />
          </Form.Item>
        </ConfigItem>
        <ConfigItem>
          <div className="item-text">{t("LastName")}</div>
          <Form.Item name="isLastNameVisible" noStyle>
            <StyledSwitch
              defaultChecked={actionRecord?.configuration.isLastNameVisible}
            />
          </Form.Item>
        </ConfigItem>
        <ConfigItem>
          <div className="item-text">{t("state_or_Province")}</div>
          <Form.Item name="isStateVisible" noStyle>
            <StyledSwitch
              defaultChecked={actionRecord?.configuration.isStateVisible}
            />
          </Form.Item>
        </ConfigItem>
        <ConfigItem>
          <div className="item-text">{t("City_city__1")}</div>
          <Form.Item name="isCityVisible" noStyle>
            <StyledSwitch
              defaultChecked={actionRecord?.configuration.isCityVisible}
            />
          </Form.Item>
        </ConfigItem>
        <ConfigItem>
          <div className="item-text">{t("Country_country")}</div>
          <Form.Item name="isCountryVisible" noStyle>
            <StyledSwitch
              defaultChecked={actionRecord?.configuration.isCountryVisible}
            />
          </Form.Item>
        </ConfigItem>
        <ConfigItem>
          <div className="item-text">{t("Price_price")}</div>
          <Form.Item name="isPriceVisible" noStyle>
            <StyledSwitch
              defaultChecked={actionRecord?.configuration.isPriceVisible}
            />
          </Form.Item>
        </ConfigItem>
        {/* <ConfigItem>
          <div className="item-text">{t('Price_price')}</div>
          <Form.Item name="isFirstNameVisible" noStyle>
            <StyledSwitch defaultChecked={actionRecord?.configuration.isFirstNameVisible} />
          </Form.Item>
        </ConfigItem> */}
        <Form.Item noStyle>
          <StyledButton loading={isLoading} htmlType="submit">
            {t("Submit_submit")}
          </StyledButton>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FreelanceAction;
