import { Form, Input, Typography } from "antd";
import IconKey from "components/Icon/Key";
import Logo from "components/Icon/Logo";
import IconUser from "components/Icon/User";
import LandingFooter from "components/LandingFooter";
import { StyledButton } from "components/StyledButton";
import { LoginContainer, LoginBox, FooterContainer } from "./style";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { signIn } from "api/auth";
import { useContext, useState } from "react";
import { AuthContext, TUserStatus } from "provider/AuthProvider";
import { getUserInfo, roles, setToken } from "helpers/authCookies";
import { getAdminProfile } from "api/admin";
import { setAuthHeaders } from "helpers/axios";
import { UserStatus } from "constants/userStatus";
import LanguageSwitcher from "components/LanguageSwitcher";

const Login = () => {
  const { t } = useTranslation("translation");
  const { setLoggedInStatus } = useContext(AuthContext);
  const [error, seterror] = useState<string | null>(null);

  const { mutateAsync: getProfile } = useMutation(getAdminProfile);

  const { mutate: login, isLoading } = useMutation(signIn, {
    onSuccess: async (data) => {
      setToken(data);
      setAuthHeaders(data);
      let userInfo = getUserInfo();

      if (userInfo?.role.toLowerCase() === roles.superAdmin.toLowerCase()) {
        setLoggedInStatus(true, "Active");
      }

      if (userInfo?.role.toLowerCase() === roles.admin.toLowerCase()) {
        const profile = await getProfile(userInfo.uid);
        setLoggedInStatus(
          true,
          UserStatus[
            profile.currentStateId as keyof typeof UserStatus
          ] as TUserStatus,
          profile
        );
      }
    },
    onError: (error: any) => {
      seterror(error.response.data?.Errors.Message);
    },
  });

  const handleFinish = (values: any) => {
    login(values);
  };

  return (
    <>
      <LoginContainer>
        <LoginBox>
          <Logo />
          <LanguageSwitcher />
          <br />
          <br />
          {error && (
            <Typography.Title level={5} type="danger">
              {error}
            </Typography.Title>
          )}
          <br />
          <Form
            onFinish={handleFinish}
            onFieldsChange={() => {
              error && seterror(null);
            }}>
            <Form.Item name="email">
              <Input
                placeholder={t("Email_email")}
                prefix={<IconUser />}
                style={{
                  borderColor: "transparent",
                  borderBottomColor: "#BCBCBC",
                  padding: "10px 5px",
                }}
              />
            </Form.Item>
            <Form.Item name="password">
              <Input.Password
                placeholder={t("Password_Password")}
                prefix={<IconKey />}
                style={{
                  borderColor: "transparent",
                  borderBottomColor: "#BCBCBC",
                  padding: "10px 5px",
                }}
              />
            </Form.Item>
            <Form.Item>
              <StyledButton
                loading={isLoading}
                htmlType="submit"
                width={"w-100"}>
                {t("Sign_in")}
              </StyledButton>
            </Form.Item>
          </Form>
          <img
            height="150"
            src="/admin.png"
            alt="QR"
            style={{ position: "relative" }}
          />
        </LoginBox>
      </LoginContainer>

      <FooterContainer>
        <LandingFooter isDark />
      </FooterContainer>
    </>
  );
};

export default Login;
