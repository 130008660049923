import { Link } from "react-router-dom";
import styled from "styled-components";
import theme from "styled-theming";
import { colors } from "theme/colors";

const color = theme.variants("mode", "variant", {
  default: { normal: colors.text },
  primary: { normal: colors.primary },
});

const colorHover = theme.variants("mode", "variant", {
  default: { normal: colors.secondary },
  primary: { normal: colors.primary },
});

export const StyledLink = styled(Link)`
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: ${color};

  &:hover {
    color: ${colorHover};
  }

  &.flex {
    display: flex;
    align-items: center;
    gap: 6px;
  }
`;

StyledLink.defaultProps = {
  variant: "default",
};
