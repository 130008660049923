import { Card, Col, Descriptions, PageHeader, Row, Space, Tag } from "antd";
import React, { useEffect } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { MdDescription, MdEmail, MdMergeType, MdTitle } from "react-icons/md";
import { RiUserStarFill } from "react-icons/ri";
import { experienceLevel } from "constants/experienceLevel";
import { FaAddressCard, FaMoneyBillAlt } from "react-icons/fa";
import { jobType } from "constants/jobType";
import { paymentType } from "constants/paymentType";
import { GrOrganization } from "react-icons/gr";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { getJobCreatedDetail } from "api/admin/job";

const JobCreatedDetail = () => {

  const { id } = useParams();
  const { t } = useTranslation("translation");
  const { data, mutate } = useMutation(
    ["getJobCreatedDetail"],
    getJobCreatedDetail
  );

  useEffect(() => {
    mutate(id as string);
    // eslint-disable-next-line
  }, [id]);

  return (
    <div>
      <PageHeader
        className="site-page-header-responsive"
        onBack={() => window.history.back()}
        backIcon={<ArrowLeftOutlined />}
        title={t("job_detail")}>
        <Row justify={"space-between"}>
          <Col span={12}></Col>
          <Col style={{ textAlign: "right" }} span={12}></Col>
        </Row>
        <Card>
          <Row>
            <Col span={8} style={{ minHeight: "100px" }}>
              <Card style={{ height: "100%" }}>
                <Row className="mb-2">
                  <Space>
                    <div style={{ marginTop: 5 }}>
                      <MdTitle style={{ color: "#5088bd" }} size="28" />
                    </div>
                    <h2 style={{ color: "#5088bd" }}>{t("Title")}</h2>
                  </Space>
                </Row>
                <h2>{data?.title || "-"}</h2>
              </Card>
            </Col>
            <Col span={8} style={{ minHeight: "100px" }}>
              <Card style={{ height: "100%" }}>
                <Row className="mb-2">
                  <Space>
                    <div style={{ marginTop: 5 }}>
                      <RiUserStarFill style={{ color: "#5088bd" }} size="28" />
                    </div>
                    <h2 style={{ color: "#5088bd" }}>{t("req_exp")}</h2>
                  </Space>
                </Row>
                <h2>
                  {data?.requiredExperienceLevel || "-"}
                </h2>
              </Card>
            </Col>

            <Col span={8}>
              <Card style={{ height: "100%" }}>
                <Row className="mb-2">
                  <Space>
                    <div style={{ marginTop: 5 }}>
                      <FaMoneyBillAlt style={{ color: "#5088bd" }} size="28" />
                    </div>
                    <h2 style={{ color: "#5088bd" }}>{t("Amount")}</h2>
                  </Space>
                </Row>
                <h2>¥{data?.paymentAmount || "N/A"}</h2>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={24} style={{ minHeight: 100 }}>
              <Card style={{ height: "100%" }}>
                <Descriptions
                  size="small"
                  column={3}
                  layout="vertical"
                  colon={false}>
                  <Descriptions.Item
                    label={
                      <Row>
                        <Space>
                          <div style={{ marginTop: 5 }}>
                            <MdMergeType size="28" />
                          </div>
                          <h3>{t("Job_Type")}</h3>
                        </Space>
                      </Row>
                    }>
                    <Tag>
                    {data?.jobType || "-"}
                    </Tag>
                  </Descriptions.Item>

                  <Descriptions.Item
                    label={
                      <Row>
                        <Space>
                          <div style={{ marginTop: 5 }}>
                            <MdMergeType size="28" />
                          </div>
                          <h3>{t("Payment_type")}</h3>
                        </Space>
                      </Row>
                    }>
                    <Tag>
                      {data?.paymentType || "-"}
                    </Tag>
                  </Descriptions.Item>

                  <Descriptions.Item
                    label={
                      <Row>
                        <Space>
                          <div style={{ marginTop: 5 }}>
                            <GrOrganization size="28" />
                          </div>
                          <h3>{t("Company_name")}</h3>
                        </Space>
                      </Row>
                    }>
                    {data?.company?.name || "-"}
                  </Descriptions.Item>
                </Descriptions>
                <Row className="mt-2">
                  <Col style={{ paddingRight: 2, marginTop: 15 }} span={12}>
                    <Row>
                      <Space>
                        <div style={{ marginTop: 5 }}>
                          <FaAddressCard size="28" />
                        </div>
                        <h3>
                          {t("state_or_Province")} / {t("City_city")} /{" "}
                          {t("Country")}
                        </h3>
                      </Space>
                    </Row>
                    {data?.state || "-"} / {data?.city || "-"} / {data?.country || "-"}
                  </Col>
                  <Col style={{ paddingRight: 2, marginTop: 15 }} span={12}>
                    <Row>
                      <Space>
                        <div style={{ marginTop: 5 }}>
                          <GrOrganization size="28" />
                        </div>
                        <h3>
                          {t("Industry")}
                        </h3>
                      </Space>
                    </Row>
                    {data?.tag || "-"}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col span={12}>
                    <Col style={{ paddingRight: 2, marginTop: 15 }} span={24}>
                      <Row>
                        <Space>
                          <h3>{t("Skills")}</h3>
                        </Space>
                      </Row>
                      {data?.skills?.map((skill, index) => (
                        <Tag key={index + 80}>{skill}</Tag>
                      ))}
                    </Col>
                  </Col>
                  <Col span={12}>
                    <Col style={{ paddingRight: 2, marginTop: 15 }} span={24}>
                      <Row>
                        <Space>
                          <h3>{t("Required_Language_Level")}</h3>
                        </Space>
                      </Row>
                      {data?.languageLevels?.map((lang, index) => (
                        <Tag key={index + 1}>
                          {lang?.language} - {lang?.level}
                        </Tag>
                      ))}
                      { 
                        <Tag key={1}>
                          Mother Tongue - {data?.motherTongue}
                        </Tag>
                      }
                    </Col>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <Card>
                <Descriptions
                  size="small"
                  column={2}
                  layout="vertical"
                  colon={false}>
                  <Descriptions.Item
                    label={
                      <Row>
                        <Space>
                          <div style={{ marginTop: 5 }}>
                            <MdDescription size="28" />
                          </div>
                          <h2>{t("Description")}</h2>
                        </Space>
                      </Row>
                    }>
                    {data?.description || "-"}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
          </Row>
        </Card>
      </PageHeader>
    </div>
  )
}

export default JobCreatedDetail