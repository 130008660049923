import GlobalStyles from "GlobalStyles";
import { initAxios } from "helpers/axios";
import { QueryClient, QueryClientProvider } from "react-query";
import { HashRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Router from "./routes/Router";
import "helpers/i18next";
import AuthProvider from "provider/AuthProvider";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LOCALES } from "components/LanguageSwitcher";
import Cookies from "js-cookie";
import { cookieNames } from "constants/cookieNames";

const queryClient = new QueryClient();
initAxios();

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(Cookies.get(cookieNames.lang) || LOCALES[0].code);
    // eslint-disable-next-line
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={{ mode: "normal" }}>
        <HashRouter>
          <AuthProvider>
            <GlobalStyles />
            <Router />
          </AuthProvider>
        </HashRouter>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
