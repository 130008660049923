export const experienceLevel = [
  {
    id: 1,
    text: "Entry",
  },
  {
    id: 2,
    text: "Intermediate",
  },
  {
    id: 3,
    text: "Senior",
  },
];
