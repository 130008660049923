import styled from "styled-components";
import { colors } from "theme/colors";

export const LangSwitcherWrap = styled.button`
  position: relative;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  .flex {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .lang-dropdown {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    top: 100%;
    left: 0;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #ccc;
    z-index: 99;
    min-width: 180px;
    transform: translateY(-100%);
    transition: transform 0.3s, opacity 0.6s;
    cursor: pointer;

    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;

      li {
        padding: 8px 15px;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          color: ${colors.secondary};
        }

        &:not(:last-child) {
          border-bottom: 1px solid #ccc;
        }
      }
    }
  }

  &:focus {
    .lang-dropdown {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
