import { Input } from "antd";
import styled from "styled-components";
import theme from "styled-theming";
import { colors } from "../theme/colors";

const backgroundColor = theme.variants("mode", "variant", {
  default: { normal: colors.white },
});

const backgroundColorHover = theme.variants("mode", "variant", {
  default: { normal: colors.white },
});

const borderColor = theme.variants("mode", "variant", {
  default: { normal: colors.stroke },
});

const borderColorHover = theme.variants("mode", "variant", {
  default: { normal: colors.secondary },
});

const color = theme.variants("mode", "variant", {
  default: { normal: colors.text },
});

const placeholderColor = theme.variants("mode", "variant", {
  default: { normal: colors.grey },
});

export const StyledInput = styled(Input)`
  &.ant-input,
  &.ant-input-affix-wrapper .ant-input {
    background-color: ${backgroundColor};
    color: ${color};
    outline: none;
    height: 56px;
    border-color: ${borderColor};
    width: ${({ width }) => (width ? `${width}px` : "100%")};
    padding: 15px;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.02em;

    span {
      font-weight: 600;
    }

    &:hover,
    &:focus {
      background: ${backgroundColorHover};
      border-color: ${borderColorHover};
      box-shadow: 0 0 4px ${colors.secondaryTint};

      ~ .ant-input-prefix {
        display: block;
      }
    }

    &::placeholder {
      color: ${placeholderColor};
    }
  }

  &.ant-input-affix-wrapper .ant-input {
    height: 46px;
  }

  &.ant-input-affix-wrapper-focused {
    .ant-input-prefix {
      visibility: visible;
      width: auto;
      opacity: 1;
    }

    box-shadow: 0 0 4px ${colors.secondaryTint};
    border-color: ${borderColorHover};
  }

  .ant-input-prefix {
    visibility: hidden;
    width: 0;
    opacity: 0;
    color: #828282;
    transition: all 0.3s;
  }

  .ant-input-clear-icon {
    margin-right: 12px;

    svg {
      width: 16px;
      height: auto;
      color: #828282;
    }
  }
`;

StyledInput.defaultProps = {
  variant: "default",
};
