const IconGroupUser = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 13C11.2091 13 13 11.2091 13 9C13 6.79086 11.2091 5 9 5C6.79086 5 5 6.79086 5 9C5 11.2091 6.79086 13 9 13Z"
        fill="currentColor"
      />
      <path
        d="M19.6004 12.6002C21.92 12.6002 23.8004 10.7198 23.8004 8.4002C23.8004 6.0806 21.92 4.2002 19.6004 4.2002C17.2808 4.2002 15.4004 6.0806 15.4004 8.4002C15.4004 10.7198 17.2808 12.6002 19.6004 12.6002Z"
        fill="currentColor"
      />
      <path
        d="M19.2 14C23.503 14 27 16.3867 27 19.3333V22H19.2V19.3333C19.2 17.36 17.627 15.64 15.3 14.72C16.444 14.2533 17.783 14 19.2 14ZM8.8 14C13.103 14 16.6 16.3867 16.6 19.3333V22H1V19.3333C1 16.3867 4.497 14 8.8 14Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconGroupUser;
