import { Card, Form, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { registerAdmin } from "api/admin";
import DescriptionText from "components/DescriptionText";
import MainTitle from "components/MainTitle";
import { StyledButton } from "components/StyledButton";
import { StyledInput } from "components/StyledInput";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

const RegisterAdmin = () => {
  const { t } = useTranslation("translation");
  const [form] = useForm();
  const { mutate: register, isLoading } = useMutation(registerAdmin, {
    onSuccess: () => {
      form.resetFields();
      message.success(t("Register_Successfully"));
    },
    onError: (error: any) => {
      let Message = error.response?.data?.Errors?.Message
      if (Message.includes("First")) {
        message.error(t("First"));
      } else if (Message.includes("Last")) {
        message.error(t("Last"));
      } else if (Message.includes("Email")) {
        message.error(t("Email"));
      } else if (Message.includes("Phone")) {
        message.error(t("Phone"));
      } else {
        message.error(JSON.stringify(error.response?.data?.Errors?.Message));
      }
    },
  });

  const handleRegister = (values: any) => {
    register(values);
  };

  return (
    <>
      <MainTitle title={t("Register_Admin")} />
      <DescriptionText text={t("Register_Admin_Form")} />
      <br />
      <br />
      <Card style={{ maxWidth: "450px" }}>
        <Form form={form} onFinish={handleRegister} labelCol={{ span: 24 }}>
          <Form.Item name="firstName" label={t("FirstName")}>
            <StyledInput placeholder={t("Enter_your_Firstname")} />
          </Form.Item>
          <Form.Item name="lastName" label={t("LastName")}>
            <StyledInput placeholder={t("Enter_your_Lastname")} />
          </Form.Item>
          <Form.Item name="email" label={t("Email_email")}>
            <StyledInput placeholder="example@gmail.com" />
          </Form.Item>
          <Form.Item name="phone" label={t("Phone_phone")}>
            <StyledInput placeholder={t("Enter_your_phone_number")} />
          </Form.Item>
          <Form.Item>
            <StyledButton loading={isLoading} htmlType="submit" width={"w-100"}>
              {t("Register")}
            </StyledButton>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default RegisterAdmin;
