import { Card, Row, Col, Select, Input, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { getCompanies, ICompaniesRes } from "api/company";
import DescriptionText from "components/DescriptionText";
import { GhostBtn } from "components/GhostBtn";
import IconCaretDown from "components/Icon/CaretDown";
import IconEdit from "components/Icon/Edit";
import IconSearch from "components/Icon/Search";
import MainTitle from "components/MainTitle";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import CompaniesAction from "./CompaniesAction";
import moment from "moment";

const Companies = () => {
  const { t } = useTranslation("translation");
  const [visible, setvisible] = useState(false);
  const [isAscending, setisAscending] = useState<string>("undefined");
  const [keyword, setkeyword] = useState<string>("");
  const [actionRecord, setactionRecord] = useState<ICompaniesRes>();

  const { data, mutate } = useMutation("get-freelancers", getCompanies);

  const companiesColumns: ColumnsType<any> = [
    {
      key: "Name",
      title: t("Name_name"),
      dataIndex: "name",
      render: (_, row) => {
        return (
          <Link to={`/companies/${row?.id}`} style={{ color: "#cc00ff" }}>
            {row?.name}
          </Link>
        );
      },
    },
    {
      key: "Industry Type",
      title: t("Industry_type"),
      dataIndex: "industryTypes",
      render: (text) => text.map((t: string, i: number) =>  text.length == i + 1 ? `${t} ` : `${t}, `) || "-",
    },
    {
      key: "Email",
      title: t("Email_email"),
      dataIndex: "email",
      render: (_,row)=>{
        return(
          <a href={'mailto:' + row?.email}>{row?.email}</a>
        )
      },
    },
    {
      key: "Location",
      title: t("Location_location__2"),
      dataIndex: "location",
      render: (text) => text,
    },
    {
      key: "Phone",
      title: t("Phone_phone"),
      dataIndex: "phone",
      render: (text) => text,
    },
    {
      key: "Created Date",
      title: t("Created_Date"),
      dataIndex: "createdAt",
      render: (text) => moment.utc(text).local().format("YYYY-MM-DD"),
    },
    {
      key: "Action",
      title: t("Action_action"),
      render: (record) => (
        <GhostBtn
          onClick={() => {
            setvisible(true);
            setactionRecord(record);
          }}>
          <IconEdit />
        </GhostBtn>
      ),
    },
  ];

  const getAscendingValue = (value: string) => {
    if (value === "undefined") return undefined;
    if (value === "true") return true;
    if (value === "false") return false;
  };

  const onSearch = debounce((ev) => setkeyword(ev.target.value), 550);

  const handleSuccess = () => {
    setvisible(false);
    setactionRecord(undefined);
    mutate({ isAscending: getAscendingValue(isAscending), keyword });
  };

  useEffect(() => {
    mutate({ isAscending: getAscendingValue(isAscending), keyword });
    // eslint-disable-next-line
  }, [isAscending, keyword]);

  return (
    <>
      <MainTitle title={t("companies_companies")} />
      <DescriptionText text={t("Company_Registered")} />
      <br />
      <br />
      <Card>
        <Row align="middle" gutter={12}>
          <Col>{t("Sort_by")}:</Col>
          <Col>
            <Select
              style={{ width: 120 }}
              defaultValue={isAscending}
              suffixIcon={<IconCaretDown />}
              onChange={(val: any) => setisAscending(val)}>
              <Select.Option value={"undefined"}>{t("Default")}</Select.Option>
              <Select.Option value={"true"}>{t("Ascending")}</Select.Option>
              <Select.Option value={"false"}>{t("Descending")}</Select.Option>
            </Select>
          </Col>
          <Col>
            <Input
              prefix={<IconSearch />}
              placeholder={t("search_search")}
              onChange={onSearch}
            />
          </Col>
        </Row>
        <br />
        <Table
          rowKey={(prop) => prop.id}
          pagination={{
            pageSize: 10,
          }}
          columns={companiesColumns}
          dataSource={data}
          scroll={{ x: 1200 }}
        />
      </Card>
      <CompaniesAction
        actionRecord={actionRecord}
        visible={visible}
        onCancel={() => setvisible(false)}
        handleSuccess={handleSuccess}
      />
    </>
  );
};

export default Companies;
