import { Form, InputNumber, InputNumberProps } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { FormItemProps } from "antd/es/form/FormItem";
import { useEffect, useState } from "react";
import { InputNumberWrap } from "./style";

interface IStyledNumberProps {
  inputProps?: InputNumberProps<any>;
  formItemProps?: FormItemProps<any>;
  defaultValue?: number;
  form?: FormInstance<any>;
}

export const StyledInputNumber = ({
  inputProps,
  formItemProps,
  defaultValue,
  form,
}: IStyledNumberProps) => {
  const [value, setvalue] = useState(() => defaultValue || 0);
  const decrease = () => {
    setvalue((prev) => prev > 0 ? prev - 1 : prev );
  };

  const increase = () => {
    setvalue((prev) => prev + 1);
  };

  useEffect(() => {
    if (form && formItemProps?.name) {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        [formItemProps?.name as string]: value,
      });
    }
    // eslint-disable-next-line
  }, [value, form]);

  return (
    <>
      <InputNumberWrap>
        <button
          type="button"
          className="input-custom-button"
          onClick={decrease}>
          -
        </button>
        <InputNumber
          type="number"
          onChange={(val: any) => val == null ? setvalue(0) : setvalue(val)}
          value={value}
          controls={false}
          min={0}
        />
        <button
          type="button"
          className="input-custom-button"
          onClick={increase}>
          +
        </button>
      </InputNumberWrap>
      <Form.Item {...formItemProps} hidden noStyle>
        <InputNumber type="number" {...inputProps} />
      </Form.Item>
    </>
  );
};
