import React from "react";

const IconApprovedDoc = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 14C17.6582 14 15 16.6582 15 20C15 23.3418 17.6582 26 21 26C24.2658 26 27 23.3418 27 20C26.9241 16.6582 24.2658 14 21 14ZM23.7342 18.9367L20.6962 21.9747C20.5443 22.1266 20.3165 22.2025 20.1646 22.2025C20.0127 22.2025 19.7848 22.1266 19.6329 21.9747L18.1139 20.4557C17.8101 20.1519 17.8101 19.6962 18.1139 19.3924C18.4177 19.0886 18.8734 19.0886 19.1772 19.3924L20.1646 20.3797L22.6709 17.8734C22.9747 17.5696 23.4304 17.5696 23.7342 17.8734C24.038 18.1772 24.038 18.6329 23.7342 18.9367Z"
        fill="currentColor"
      />
      <path
        d="M17.7826 2H5.21739C3.96087 2 3 3.02667 3 4.2V21.8C3 23.0467 3.96087 24 5.21739 24H14.8261C13.9391 22.8267 13.3478 21.2867 13.3478 19.6733C13.3478 19.38 13.3478 19.16 13.4217 18.8667H6.69565C6.25217 18.8667 5.95652 18.5733 5.95652 18.1333C5.95652 17.7667 6.25217 17.4 6.69565 17.4H13.7913C13.9391 16.8867 14.1609 16.3733 14.4565 15.9333H6.69565C6.25217 15.9333 5.95652 15.64 5.95652 15.2C5.95652 14.8333 6.25217 14.4667 6.69565 14.4667H15.6391C16.8217 13.3667 18.3 12.6333 20 12.4133V4.2C20 3.02667 18.9652 2 17.7826 2ZM6.69565 5.66667H12.6087C12.9783 5.66667 13.3478 6.03333 13.3478 6.4C13.3478 6.84 12.9783 7.13333 12.6087 7.13333H6.69565C6.25217 7.13333 5.95652 6.84 5.95652 6.4C5.95652 6.03333 6.25217 5.66667 6.69565 5.66667ZM16.3043 13H6.69565C6.25217 13 5.95652 12.7067 5.95652 12.2667C5.95652 11.9 6.25217 11.5333 6.69565 11.5333H16.3043C16.6739 11.5333 17.0435 11.9 17.0435 12.2667C17.0435 12.7067 16.6739 13 16.3043 13ZM16.3043 10.0667H6.69565C6.25217 10.0667 5.95652 9.77333 5.95652 9.33333C5.95652 8.96667 6.25217 8.6 6.69565 8.6H16.3043C16.6739 8.6 17.0435 8.96667 17.0435 9.33333C17.0435 9.77333 16.6739 10.0667 16.3043 10.0667Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconApprovedDoc;
