import styled from "styled-components";

export const InputNumberWrap = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  height: 46px;

  button.input-custom-button {
    border: 1px solid #bcbcbc;
    background-color: #fff;
    width: 54px;
    cursor: pointer;
    outline: 0;

    &:hover {
      border-color: #00b674;
    }
  }

  .ant-input-number {
    border-left: 0;
    border-right: 0;

    &:hover {
      border-color: #00b674;
    }
  }

  .ant-input-number-input-wrap {
    height: 100%;
  }

  input {
    height: 100%;
    text-align: center;
  }
`;
