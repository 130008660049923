import { Card, Col, PageHeader, Row, Space } from "antd";
import { getConsultantDetail } from "api/consultant";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaAddressCard, FaCity, FaUserAlt } from "react-icons/fa";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";

const PendingRequestConsultantDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation("translation");
  const { data, mutate } = useMutation("getProfile", getConsultantDetail);
  useEffect(() => {
    mutate(id as string);
    // eslint-disable-next-line
  }, [id]);
  return (
    <div>
      <PageHeader
        title={`${t("consultant_detail")}: ${data?.name}`}
        onBack={() => window.history.back()}
        className="site-page-header-responsive"
        style={{ padding: 0 }}>
        <Card>
          <Row>
            <Col md={24} style={{ minHeight: 100 }}>
              <Card style={{ height: "100%" }}>
                <Row className="mt-2">
                  <Col style={{ paddingRight: 20, marginTop: 15 }} span={12}>
                    <Row>
                      <Space>
                        <div style={{ marginTop: 5 }}>
                          <FaUserAlt size="28" />
                        </div>
                        <h2>{t("Consultancy_Name")}</h2>
                      </Space>
                    </Row>
                    {data?.name || "-"}
                  </Col>
                  <Col style={{ paddingRight: 2, marginTop: 20 }} span={12}>
                    <Row>
                      <Space>
                        <div style={{ marginTop: 5 }}>
                          <BsFillTelephoneFill size="28" />
                        </div>
                        <h3>{t("Phone_phone")}</h3>
                      </Space>
                    </Row>
                    {data?.phone || "-"}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col style={{ paddingRight: 20, marginTop: 15 }} span={12}>
                    <Row>
                      <Space>
                        <div style={{ marginTop: 5 }}>
                          <FaAddressCard size="28" />
                        </div>
                        <h2>{t("Country")}</h2>
                      </Space>
                    </Row>
                    {data?.country || "-"}
                  </Col>
                  <Col style={{ paddingRight: 2, marginTop: 20 }} span={12}>
                    <Row>
                      <Space>
                        <div style={{ marginTop: 5 }}>
                          <FaAddressCard size="28" />
                        </div>
                        <h3>{t("state_or_Province")}</h3>
                      </Space>
                    </Row>
                    {data?.state || "-"}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col style={{ paddingRight: 20, marginTop: 15 }} span={12}>
                    <Row>
                      <Space>
                        <div style={{ marginTop: 5 }}>
                          <FaCity size="28" />
                        </div>
                        <h2>{t("City_city")}</h2>
                      </Space>
                    </Row>
                    {data?.city || "-"}
                  </Col>
                  <Col style={{ paddingRight: 2, marginTop: 20 }} span={12}>
                    <Row className="mr-2">
                      <Space>
                        <div style={{ marginTop: 5 }}>
                          <FaAddressCard size="28" />
                        </div>
                        <h3>{t("Other_address")}</h3>
                      </Space>
                    </Row>
                    {data?.otherAddress || "-"}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col style={{ paddingRight: 20, marginTop: 15 }} span={12}>
                    <Row>
                      <Space>
                        <div style={{ marginTop: 5 }}>
                          <FaAddressCard size="28" />
                        </div>
                        <h2>{t("House_number")}</h2>
                      </Space>
                    </Row>
                    {data?.houseNumber || "-"}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Card>
      </PageHeader>
    </div>
  );
};

export default PendingRequestConsultantDetail;
