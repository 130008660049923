import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

const TOKEN = "mirai_token";

export const roles = {
  candidate: "Candidate",
  company: "Company",
  consultant: "Consultant",
  admin: "Admin",
  superAdmin: "Superadmin",
};

export type TUserInfo = {
  email: string;
  uid: string;
  role: keyof typeof roles;
  iss: string;
  exp: string;
};

export const getToken = () => Cookies.get(TOKEN);

export const setToken = (token: string) => Cookies.set(TOKEN, token);

export const deleteToken = () => Cookies.remove(TOKEN);

export const getUserInfo = () => {
  let token = getToken();
  if (token) return jwtDecode(token) as TUserInfo;
  else return null;
};
