import React from "react";

const IconChevronDown = () => {
  return (
    <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.928711 2.05417L7.99954 9.125L15.0704 2.05417L13.8912 0.875L7.99954 6.76833L2.10704 0.875L0.928711 2.05417Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconChevronDown;
