import styled from "styled-components";

export const SidebarMenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 14px 14px 14px 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.02em;
  color: #828282;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 27px;
  }

  .icon-wrap {
    width: 28px;
    height: 28px;
  }

  &.active {
    background: #ebf7fd;
    color: #009bde;
  }
`;
