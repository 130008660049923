const IconHeart = () => {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0007 21.9083L10.309 20.3683C4.30065 14.92 0.333984 11.3267 0.333984 6.91667C0.333984 3.32333 3.15732 0.5 6.75065 0.5C8.78065 0.5 10.729 1.445 12.0007 2.93833C13.2723 1.445 15.2207 0.5 17.2507 0.5C20.844 0.5 23.6673 3.32333 23.6673 6.91667C23.6673 11.3267 19.7007 14.92 13.6923 20.38L12.0007 21.9083Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconHeart;
