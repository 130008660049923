import { Col, Form, Modal, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import { updateCompanyConfig } from "api/admin/company";
import { ICompaniesRes } from "api/company";
import {
  ConfigCardTitle,
  ConfigItem,
  ConfigItemText,
} from "components/ConfigCard";
import { StyledButton } from "components/StyledButton";
import { StyledInputNumber } from "components/StyledInputNumber";
import { StyledSwitch } from "components/StyledSwitch";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

interface ICompaniesActionProps {
  actionRecord?: ICompaniesRes;
  handleSuccess: () => void;
  visible: boolean;
  onCancel: () => void;
}

const CompaniesAction = ({
  visible,
  onCancel,
  actionRecord,
  handleSuccess,
}: ICompaniesActionProps) => {
  const { t } = useTranslation("translation");
  const { mutate, isLoading } = useMutation(updateCompanyConfig, {
    onSuccess: handleSuccess,
  });
  const [form] = useForm();

  const handleFinish = (values: any) => {
    mutate({
      ...values,
      configurationId: actionRecord?.configuration.id,
    });
  };

  useEffect(() => {
    if (actionRecord) {
      form.setFieldsValue(actionRecord.configuration);
    }
    // eslint-disable-next-line
  }, [actionRecord]);

  return (
    <Modal
      destroyOnClose
      onCancel={onCancel}
      visible={visible}
      footer={null}
      title={null}>
      <ConfigCardTitle>{t("Manage_Details")}</ConfigCardTitle>
      <Form form={form} onFinish={handleFinish}>
        <ConfigItem>
          <ConfigItemText>{t("Company_name")}</ConfigItemText>
          <Form.Item valuePropName="checked" noStyle name="isNameVisible">
            <StyledSwitch />
          </Form.Item>
        </ConfigItem>
        <ConfigItem>
          <ConfigItemText>{t("Country_country")}</ConfigItemText>
          <Form.Item valuePropName="checked" noStyle name="isCountryVisible">
            <StyledSwitch />
          </Form.Item>
        </ConfigItem>
        <ConfigItem>
          <ConfigItemText>{t("City_city__1")}</ConfigItemText>
          <Form.Item valuePropName="checked" noStyle name="isCityVisible">
            <StyledSwitch />
          </Form.Item>
        </ConfigItem>
        <ConfigItem>
          <ConfigItemText>{t("state_or_Province")}</ConfigItemText>
          <Form.Item valuePropName="checked" noStyle name="isStateVisible">
            <StyledSwitch />
          </Form.Item>
        </ConfigItem>
        <Row gutter={56}>
          <Col span={12}>
            <ConfigItemText>{t("Favourite_Limit")}</ConfigItemText>
            <br />
            <StyledInputNumber
              form={form}
              defaultValue={actionRecord?.configuration.favoriteCountLimit}
              formItemProps={{ noStyle: true, name: "favoriteCountLimit" }}
            />
          </Col>
          <Col span={12}>
            <ConfigItemText>{t("Favourite_Period")}</ConfigItemText>
            <br />
            <StyledInputNumber
              form={form}
              defaultValue={actionRecord?.configuration.favoritePeriodLimit}
              formItemProps={{ noStyle: true, name: "favoritePeriodLimit" }}
            />
          </Col>
          <Col>
            <br />
            <StyledButton loading={isLoading} htmlType="submit">
              {t("Submit_submit")}
            </StyledButton>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CompaniesAction;
