import { Card, Row, Col, Select, Input, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { getAdminCandidateFavorite } from "api/admin/candidate";
import DescriptionText from "components/DescriptionText";
import { GhostBtn } from "components/GhostBtn";
import IconCaretDown from "components/Icon/CaretDown";
import IconEdit from "components/Icon/Edit";
import IconSearch from "components/Icon/Search";
import MainTitle from "components/MainTitle";
import { debounce } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
const CompanyFavoriteAction = React.lazy(
  () => import("./CompanyFavoriteAction")
);

const CompanyFavourite = () => {
  const { t } = useTranslation("translation");

  const [visible, setvisible] = useState(false);
  const [id, setId] = useState<string>();

  const [isAscending, setisAscending] = useState<string>("undefined");
  const [keyword, setkeyword] = useState<string>("");
  const getAscendingValue = (value: string) => {
    if (value === "undefined") return "desc";
    if (value === "true") return "asc";
    if (value === "false") return "desc";
  };

  const onSearch = debounce((ev) => setkeyword(ev.target.value), 550);
  const { data, mutate } = useMutation(
    "get-candidate-favorite",
    getAdminCandidateFavorite
  );

  const handleSuccess = () => {
    setvisible(false);
    setId(undefined);
    mutate({ sortOrder: getAscendingValue(isAscending), keyword: keyword});
  };

  useEffect(() => {
    mutate({ sortOrder: getAscendingValue(isAscending), keyword: keyword});
    // eslint-disable-next-line
  }, [isAscending, keyword]);

  const companiesColumns: ColumnsType<any> = [
    {
      key: "Companies Name",
      title: t("Companies_name"),
      dataIndex: "companyName",
      render: (text) => text,
    },
    {
      key: "Companies Email",
      title: `${t("companie_companie")} ${t("Email_email")}`,
      dataIndex: "companyEmail",
      render: (_,row)=>{
        return(
          <a href={'mailto:' + row?.companyEmail}>{row?.companyEmail}</a>
        )
      },
    },
    {
      key: "Favourited To",
      title: t("Favourited_to"),
      dataIndex: "candidateName",
      render: (_, row) => {
        return (
          <Link
            to={`/company-favourite/${row?.id}`}
            style={{ color: "#cc00ff" }}>
            {row?.candidateName}
          </Link>
        );
      },
    },
    {
      key: "Favourited Email",
      title: `Favourited ${t("Email_email")}`,
      dataIndex: "candidateEmail",
      render: (text) => text,
    },
    {
      key: "Favourite Date",
      title: t("Favourite_date"),
      dataIndex: "favoritedDate",
      render: (text) => moment.utc(text).local().format("YYYY-MM-DD"),
    },
    {
      key: "End Date",
      title: t("End_date"),
      dataIndex: "validity",
      render: (text) => moment.utc(text).local().format("YYYY-MM-DD"),
    },
    {
      key: "Action",
      title: t("Action_action"),
      render: (record) => (
        <GhostBtn
          onClick={() => {
            setvisible(true);
            setId(record?.id);
          }}>
          <IconEdit />
        </GhostBtn>
      ),
    },
  ];

  return (
    <>
      <MainTitle title={t("company_favourite")} />
      <DescriptionText text={t("Company_favourite")} />
      <br />
      <br />
      <Card>
        <Row align="middle" gutter={12}>
          <Col>{t("Sort_by")}:</Col>
          <Col>
            <Select
              style={{ width: 120 }}
              defaultValue={isAscending}
              suffixIcon={<IconCaretDown />}
              onChange={(val: any) => setisAscending(val)}>
              <Select.Option value={"undefined"}>{t("Default")}</Select.Option>
              <Select.Option value={"true"}>{t("Ascending")}</Select.Option>
              <Select.Option value={"false"}>{t("Descending")}</Select.Option>
            </Select>
          </Col>
          <Col>
            <Input
              prefix={<IconSearch />}
              placeholder={t("search_search")}
              onChange={onSearch}
            />
          </Col>
          {/* <Col>
            <Select defaultValue="A-Z" suffixIcon={<IconCaretDown />}>
              <Select.Option value="A-Z">{t("pending_pending")}</Select.Option>
            </Select>
          </Col> */}
        </Row>
        <br />
        <Table
          rowKey={(prop) => prop.id}
          pagination={{
            pageSize: 10,
          }}
          columns={companiesColumns}
          dataSource={data || []}
          scroll={{ x: 1200 }}
        />
      </Card>
      <CompanyFavoriteAction
        handleSuccess={handleSuccess}
        id={id}
        visible={visible}
        onCancel={() => setvisible(false)}
      />
    </>
  );
};

export default CompanyFavourite;
