import { Card, Row, Col, Table, Popover, Select, Input, Dropdown, Menu } from "antd";
import { ColumnsType } from "antd/lib/table";
import { deleteAdmin, getAdmins, IGetAdminsRes } from "api/admin";
import DescriptionText from "components/DescriptionText";
import { GhostBtn } from "components/GhostBtn";
import IconBin from "components/Icon/Bin";
import IconEdit from "components/Icon/Edit";
import MainTitle from "components/MainTitle";
import { StyledButton } from "components/StyledButton";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UseMutateAsyncFunction, useMutation } from "react-query";
import CompaniesAction from "./CompaniesAction";
import { debounce } from "lodash";
import IconCaretDown from "components/Icon/CaretDown";
import IconSearch from "components/Icon/Search";
import moment from "moment";

const RegisteredAdmin = () => {
  const { t } = useTranslation("translation");
  const [visible, setvisible] = useState(false);
  const [actionRecord, setactionRecord] = useState<IGetAdminsRes>();
  const [data, setdata] = useState<IGetAdminsRes[]>([]);
  const [isAscending, setisAscending] = useState<string>("undefined");
  const [keyword, setkeyword] = useState<string>("");

  const getAscendingValue = (value: string) => {
    if (value === "undefined") return "desc";
    if (value === "true") return "asc";
    if (value === "false") return "desc";
  };

  const onSearch = debounce((ev) => setkeyword(ev.target.value), 550);

  const { mutateAsync: getAllAdmins, reset: resetGetAdmins } = useMutation(
    getAdmins,
    {
      onSuccess: (res) => {
        setdata(res);
      },
    }
  );

  const { mutateAsync: deleteAdminById, isLoading: deleting } = useMutation(
    "delete-admins",
    deleteAdmin,
    {
      onSuccess: () => {
        const sortValue = getAscendingValue(isAscending);
        getAllAdmins({ sortOrder: sortValue, keyword: keyword});
      },
    }
  );

  const companiesColumns: ColumnsType<any> = [
    {
      key: "Full Name",
      title: t("Full_Name"),
      render: (record) => record.firstName + " " + record.lastName,
    },
    {
      key: "Email",
      title: t("Email_email"),
      dataIndex: "email",
      render: (text) => <a href={'mailto:' + text} > {text} </a> ,
    },
    {
      key: "Phone",
      title: t("Phone_phone"),
      dataIndex: "phone",
      render: (text) => text,
    },
    {
      key: "Created Date",
      title: t("Created_Date"),
      dataIndex: "createdAt",
      render: (text) => moment.utc(text).local().format("YYYY-MM-DD"),
    },
    {
      key: "Action",
      title: t("Action_action"),
      render: (record) => (
        <ActionBtns
          setvisible={setvisible}
          deleteAdminById={deleteAdminById}
          deleting={deleting}
          record={record}
          setactionRecord={setactionRecord}
        />
      ),
    },
  ];

  const handleSuccess = () => {
    const sortValue = getAscendingValue(isAscending);
    setvisible(false);
    setactionRecord(undefined);
    getAllAdmins({ sortOrder: sortValue, keyword: keyword});
  };

  useEffect(() => {
    const sortValue = getAscendingValue(isAscending);
    getAllAdmins({ sortOrder: sortValue, keyword: keyword});

    return () => {
      resetGetAdmins();
    };
    // eslint-disable-next-line
  }, [isAscending, keyword]);

  return (
    <>
      <MainTitle title={t("Registered_Admin")} />
      <DescriptionText text={t("All_Registered_Admin")} />
      <br />
      <br />
      <Card>
      <Row align="middle" gutter={12}>
        <Col>{t("Sort_by")}:</Col>
        <Col>
            <Select
            style={{ width: 120 }}
            defaultValue={isAscending}
            suffixIcon={<IconCaretDown />}
            onChange={(val: any) => setisAscending(val)}>
            <Select.Option value={"undefined"}>{t("Default")}</Select.Option>
            <Select.Option value={"true"}>{t("Ascending")}</Select.Option>
            <Select.Option value={"false"}>{t("Descending")}</Select.Option>
            </Select>
        </Col>
        <Col>
            <Input
            prefix={<IconSearch />}
            placeholder={t("search_search")}
            onChange={onSearch}
            />
        </Col>
        </Row>
        <br/>
        <Table
          rowKey={(prop) => prop.id}
          pagination={{
            pageSize: 10,
          }}
          columns={companiesColumns}
          dataSource={data}
          scroll={{ x: 1200 }}
        />
      </Card>
      <CompaniesAction
        actionRecord={actionRecord}
        visible={visible}
        onCancel={() => setvisible(false)}
        handleSuccess={handleSuccess}
      />
    </>
  );
};

interface IActionBtnsProps {
  setvisible: React.Dispatch<React.SetStateAction<boolean>>;
  setactionRecord: React.Dispatch<
    React.SetStateAction<IGetAdminsRes | undefined>
  >;
  record: IGetAdminsRes;
  deleting: boolean;
  deleteAdminById: UseMutateAsyncFunction<any, unknown, string, unknown>;
}

const ActionBtns = ({
  setvisible,
  setactionRecord,
  record,
  deleting,
  deleteAdminById,
}: IActionBtnsProps) => {
  const [popOverVisible, setpopOverVisible] = useState(false);
  const { t } = useTranslation("translation");

  const Confirm = () => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => deleteAdminById(record.id)
          }
          style={{ padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <span style={{ padding: '10px 40px', fontSize: '16px', backgroundColor: '#00B674', color: 'white'}}>
            {t("Confirm_confirm")}
          </span>
        </Menu.Item>
      </Menu>
    );
  }
  return (
    <Row align="middle" gutter={20}>
      <Col>
        <GhostBtn
          onClick={() => {
            setvisible(true);
            setactionRecord(record);
          }}>
          <IconEdit />
        </GhostBtn>
      </Col>
      <Col>
        <Dropdown
            overlay={<Confirm />}
            trigger={[`click`]}
          >
          <GhostBtn>
            <IconBin />
          </GhostBtn>
        </Dropdown>
        {/* <Popover
          visible={popOverVisible}
          content={
            <StyledButton
              loading={deleting}
              onClick={() => {
                deleteAdminById(record.id);
                setpopOverVisible(false);
              }}
              children={t("Confirm_confirm")}
            />
          }
          onVisibleChange={(visible) => setpopOverVisible(visible)}
          trigger="focus">
          <GhostBtn>
            <IconBin />
          </GhostBtn>
        </Popover> */}
      </Col>
    </Row>
  );
};

export default RegisteredAdmin;
