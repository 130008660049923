import React from "react";

const IconEdit = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5044 1.44804C14.1765 0.791411 15.0804 0.42623 16.02 0.431701C16.9596 0.437173 17.8592 0.812857 18.5236 1.47727C19.188 2.14168 19.5637 3.04125 19.5692 3.98085C19.5746 4.92046 19.2095 5.82434 18.5528 6.49644L17.75 7.29924L12.7016 2.25084L13.5056 1.44804H13.5044ZM11.8532 3.10044L2.25443 12.6992C1.86165 13.0925 1.58901 13.5895 1.46843 14.132L0.41483 18.872C0.392794 18.9707 0.396024 19.0733 0.424221 19.1703C0.452418 19.2673 0.504669 19.3557 0.576126 19.4271C0.647583 19.4986 0.735931 19.5509 0.832973 19.5791C0.930015 19.6072 1.03261 19.6105 1.13123 19.5884L5.84243 18.5408C6.40335 18.416 6.91704 18.1337 7.32323 17.7272L16.9016 8.14884L11.8532 3.10044Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconEdit;
