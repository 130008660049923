import { Button, message, Modal, Popconfirm } from "antd";
import { consultantVerify } from "api/consultant";
import { ConfigCardTitle } from "components/ConfigCard";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

const PendingRequestAction = ({
  visible,
  onCancel,
  selected,
  handleVerified,
}: any) => {
  const { t } = useTranslation("translation");

  const { mutate: verify, isLoading } = useMutation(consultantVerify, {
    onSuccess: () => {
      handleVerified();
    },
    onError: (err: any) => {
      message.error(err.response?.data.Errors.Message);
    },
  });

  const sendCode = () => {
    verify(selected?.consultantId);
  };

  return (
    <Modal
      onCancel={onCancel}
      visible={visible}
      footer={null}
      title={null}
      bodyStyle={{ textAlign: "center" }}>
      <ConfigCardTitle>{selected?.name}</ConfigCardTitle>
      <Popconfirm
        title={`${t("ApproveConsult")}`}
        onConfirm={sendCode}
        okText="Yes"
        cancelText="No">
        <Button type="primary" loading={isLoading}>
          {t("Send_Code")}
        </Button>
      </Popconfirm>
    </Modal>
  );
};

export default PendingRequestAction;
