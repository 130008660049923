const IconBuilding = () => {
  return (
    <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.9091 9.625V0.875H0V27.125H10.1818V21.875H12.7273V27.125H22.9091V18.375V9.625ZM6.36364 23.625H3.81818V21.875H6.36364V23.625ZM6.36364 19.25H3.81818V17.5H6.36364V19.25ZM6.36364 14.875H3.81818V13.125H6.36364V14.875ZM6.36364 10.5H3.81818V8.75H6.36364V10.5ZM6.36364 6.125H3.81818V4.375H6.36364V6.125ZM16.5455 4.375H19.0909V6.125H16.5455V4.375ZM12.7273 19.25H10.1818V17.5H12.7273V19.25ZM12.7273 14.875H10.1818V13.125H12.7273V14.875ZM12.7273 10.5H10.1818V8.75H12.7273V10.5ZM12.7273 6.125H10.1818V4.375H12.7273V6.125ZM19.0909 23.625H16.5455V21.875H19.0909V23.625ZM19.0909 19.25H16.5455V17.5H19.0909V19.25ZM19.0909 14.875H16.5455V13.125H19.0909V14.875ZM19.0909 10.5H16.5455V8.75H19.0909V10.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconBuilding;
