import ChangePassword from "pages/ChangePassword";
import Login from "pages/Login";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import SidebarLayout from "layout/Sidebar";
import { ADMIN_ROUTES } from "./routes";
import { memo, useContext } from "react";
import { AuthContext } from "provider/AuthProvider";
import ProtectedRoutes from "./ProtectedRoutes";
import { getToken } from "helpers/authCookies";
import Loader from "components/Loader";
import { routes } from "constants/routes";
import PrivacyPolicy from "components/PrivacyPolicy/PrivacyPolicy";

const REDIRECT_PATH = "/";

const Router = () => {
  const { isLoggedIn, userStatus } = useContext(AuthContext);
  const token = getToken();

  if (token && !isLoggedIn) {
    return <Loader variant="overlay" />;
  }

  return (
    <Routes>
      <Route path="">
        <Route
          index
          element={
            isLoggedIn ? <Navigate to={routes.freelancers} /> : <Login />
          }
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path={routes.changePassword}
          element={
            userStatus === "Onboarding" ? (
              <ChangePassword />
            ) : (
              <Navigate to={routes.freelancers} />
            )
          }
        />
        <Route
          element={
            <ProtectedRoutes
              redirectPath={REDIRECT_PATH}
              isLoggedIn={isLoggedIn}
              userStatus={userStatus}>
              <Outlet />
            </ProtectedRoutes>
          }>
          {ADMIN_ROUTES.map((item) => (
            <Route
              key={item.key}
              path={item.path}
              element={
                <SidebarLayout>
                  <item.Element />
                </SidebarLayout>
              }
            />
          ))}
        </Route>
      </Route>
    </Routes>
  );
};

export default memo(Router);
