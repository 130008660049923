export const UserStatusID = {
  Onboarding: 1,
  Active: 2,
  Closed: 3,
};

export const UserStatus = {
  1: "Onboarding",
  2: "Active",
  3: "Closed",
};
