import React from "react";

const IconBin = () => {
  return (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.125 3.875V4.25H11.875V3.875C11.875 3.37772 11.6775 2.90081 11.3258 2.54917C10.9742 2.19754 10.4973 2 10 2C9.50272 2 9.02581 2.19754 8.67418 2.54917C8.32254 2.90081 8.125 3.37772 8.125 3.875ZM6.625 4.25V3.875C6.625 2.97989 6.98058 2.12145 7.61351 1.48851C8.24645 0.855579 9.10489 0.5 10 0.5C10.8951 0.5 11.7535 0.855579 12.3865 1.48851C13.0194 2.12145 13.375 2.97989 13.375 3.875V4.25H19C19.1989 4.25 19.3897 4.32902 19.5303 4.46967C19.671 4.61032 19.75 4.80109 19.75 5C19.75 5.19891 19.671 5.38968 19.5303 5.53033C19.3897 5.67098 19.1989 5.75 19 5.75H17.869L16.45 18.176C16.3454 19.0911 15.9076 19.9358 15.2201 20.5488C14.5326 21.1618 13.6436 21.5004 12.7225 21.5H7.2775C6.3564 21.5004 5.46735 21.1618 4.77989 20.5488C4.09243 19.9358 3.65464 19.0911 3.55 18.176L2.131 5.75H1C0.801088 5.75 0.610322 5.67098 0.46967 5.53033C0.329018 5.38968 0.25 5.19891 0.25 5C0.25 4.80109 0.329018 4.61032 0.46967 4.46967C0.610322 4.32902 0.801088 4.25 1 4.25H6.625ZM8.5 9.125C8.5 8.92609 8.42098 8.73532 8.28033 8.59467C8.13968 8.45402 7.94891 8.375 7.75 8.375C7.55109 8.375 7.36032 8.45402 7.21967 8.59467C7.07902 8.73532 7 8.92609 7 9.125V16.625C7 16.8239 7.07902 17.0147 7.21967 17.1553C7.36032 17.296 7.55109 17.375 7.75 17.375C7.94891 17.375 8.13968 17.296 8.28033 17.1553C8.42098 17.0147 8.5 16.8239 8.5 16.625V9.125ZM12.25 8.375C12.0511 8.375 11.8603 8.45402 11.7197 8.59467C11.579 8.73532 11.5 8.92609 11.5 9.125V16.625C11.5 16.8239 11.579 17.0147 11.7197 17.1553C11.8603 17.296 12.0511 17.375 12.25 17.375C12.4489 17.375 12.6397 17.296 12.7803 17.1553C12.921 17.0147 13 16.8239 13 16.625V9.125C13 8.92609 12.921 8.73532 12.7803 8.59467C12.6397 8.45402 12.4489 8.375 12.25 8.375Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconBin;
