import { Button, ButtonProps } from "antd";
import styled from "styled-components";
import { colors } from "../theme/colors";
import theme from "styled-theming";

const backgroundColor = theme.variants("mode", "variant", {
  default: { normal: colors.secondary },
  primary: { normal: colors.primary },
  secondaryOutline: { normal: "transparent" },
  cancel: { normal: colors.white },
  back: { normal: colors.white },
  transparent: { normal: "transparent" },
});

const backgroundColorHover = theme.variants("mode", "variant", {
  default: { normal: "#00A468" },
  primary: { normal: "#008cc9" },
  secondaryOutline: { normal: "#00A468" },
  cancel: { normal: colors.errorTint },
  back: { normal: "#E8E9EA" },
  transparent: { normal: "transparent" },
});

const borderColor = theme.variants("mode", "variant", {
  default: { normal: colors.secondary },
  primary: { normal: colors.primary },
  secondaryOutline: { normal: colors.secondary },
  cancel: { normal: colors.error },
  back: { normal: colors.text },
  transparent: { normal: "none" },
});

const borderColorHover = theme.variants("mode", "variant", {
  default: { normal: "#00A468" },
  primary: { normal: "#0d98d5" },
  secondaryOutline: { normal: colors.secondary },
  cancel: { normal: colors.error },
  back: { normal: colors.text },
  transparent: { normal: "none" },
});

const color = theme.variants("mode", "variant", {
  default: { normal: colors.white },
  primary: { normal: colors.white },
  secondaryOutline: { normal: colors.secondary },
  cancel: { normal: colors.error },
  back: { normal: colors.text },
  transparent: { normal: colors.text },
});

const colorHover = theme.variants("mode", "variant", {
  default: { normal: colors.white },
  primary: { normal: colors.white },
  secondaryOutline: { normal: colors.white },
  cancel: { normal: colors.error },
  back: { normal: colors.text },
  transparent: { normal: colors.primary },
});

interface IStyledButton extends ButtonProps {
  variant?: "default" | "primary" | "secondaryOutline" | "cancel" | "back" | "transparent";
  width?: number | "w-100";
}

export const StyledButton = styled(Button)<IStyledButton>`
  background-color: ${backgroundColor};
  color: ${color};
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  border-color: ${borderColor};
  width: ${({ width }) => (width === "w-100" ? "100%" : typeof width === "number" ? `${width}px` : "255px")};

  span {
    font-weight: 600;
  }

  &:hover,
  &:focus {
    background: ${backgroundColorHover};
    color: ${colorHover};
    border-color: ${borderColorHover};
  }
`;

StyledButton.defaultProps = {
  variant: "default",
  htmlType: "button",
};
