export const consultantStatus = [
  {
    id: 1,
    text: "Onboarding",
  },
  {
    id: 2,
    text: "Active",
  },
  {
    id: 3,
    text: "Closed",
  },
];
