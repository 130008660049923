import React from "react";

const IconKey = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.815 14.6317L9.784 18.6627H7.115V21.3307H4.447V23.9987H0V19.5517L9.368 10.1837C9.05168 9.34241 8.89106 8.45056 8.894 7.55174C8.89427 5.94066 9.40963 4.37186 10.3648 3.07447C11.32 1.77707 12.6649 0.819034 14.2032 0.340273C15.7415 -0.138488 17.3926 -0.112893 18.9153 0.413318C20.438 0.939529 21.7526 1.93879 22.6671 3.26517C23.5816 4.59155 24.0481 6.17556 23.9984 7.78588C23.9487 9.39619 23.3855 10.9485 22.3911 12.216C21.3966 13.4835 20.0229 14.3998 18.4706 14.8312C16.9184 15.2626 15.2689 15.1864 13.763 14.6137L13.815 14.6307V14.6317ZM21.347 5.32174V5.31874C21.347 4.7911 21.1905 4.27531 20.8974 3.83658C20.6043 3.39785 20.1877 3.05589 19.7002 2.85392C19.2128 2.65196 18.6764 2.59907 18.1589 2.70193C17.6414 2.80479 17.166 3.0588 16.7928 3.43182C16.4196 3.80485 16.1654 4.28015 16.0624 4.79762C15.9593 5.3151 16.012 5.85151 16.2138 6.33905C16.4156 6.82658 16.7574 7.24333 17.196 7.53661C17.6346 7.8299 18.1504 7.98654 18.678 7.98674H18.679C19.386 7.98647 20.064 7.70571 20.5642 7.20607C21.0644 6.70643 21.3459 6.02873 21.347 5.32174Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconKey;
