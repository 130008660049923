import { Form, Input, message } from "antd";
import IconUser from "components/Icon/User";
import LandingFooter from "components/LandingFooter";
import { StyledButton } from "components/StyledButton";
import { FPContainer, FpBox } from "./style";
import { FooterContainer } from "pages/Login/style";
import MainTitle from "components/MainTitle";
import DescriptionText from "components/DescriptionText";
import { useMutation } from "react-query";
import { changeAdminPassword, getAdminProfile } from "api/admin";
import { useContext } from "react";
import { AuthContext } from "provider/AuthProvider";
import { getUserInfo } from "helpers/authCookies";
import { useTranslation } from "react-i18next";

const ResetPassword = () => {
  const { profile, setLoggedInStatus } = useContext(AuthContext);
  const { t } = useTranslation("translation");

  const { mutateAsync: adminProfile } = useMutation(getAdminProfile);

  const { mutate: changePassword, isLoading } = useMutation(
    changeAdminPassword,
    {
      onSuccess: async (data) => {
        if (!data.error) {
          message.success(data.message);
          const userInfo = getUserInfo();
          const profile = await adminProfile(userInfo?.uid!);
          setLoggedInStatus(true, "Active", profile);
        } else {
          message.error(data.message);
        }
      }
    }
  );

  const handleFinish = (values: any) => {
    changePassword({
      email: profile?.email!,
      ...values,
    });
  };

  const passwordValidator = (_: any, value: any) => {
    const numbers = /\d/;
    if (!numbers.test(value)) {
      return Promise.reject(`${t("PasswordEight")}`);
    }

    if (value.length < 6) {
      return Promise.reject(`${t("PasswordSix")}`);
    }

    return Promise.resolve();
  };

  const confirmPasswordValidation = (
    password: string,
    confirmPassword: string
  ) => {
    if (password !== confirmPassword) {
      return Promise.reject(`${t("PasswordNotMatch")}`);
    }

    return Promise.resolve();
  };

  return (
    <>
      <FPContainer>
        <FpBox>
          <MainTitle title={t("Change_Password")} />
          <DescriptionText text={t("Password_length")} />
          <br />
          <Form onFinish={handleFinish}>
            <Form.Item name="oldPassword">
              <Input.Password
                placeholder={t("Current_Password")}
                prefix={<IconUser />}
                style={{
                  borderColor: "transparent",
                  borderBottomColor: "#BCBCBC",
                  padding: "10px 5px",
                }}
              />
            </Form.Item>
            <Form.Item
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: t("Password_Verify"),
                },
                {
                  validator: passwordValidator,
                },
              ]}>
              <Input.Password
                placeholder={t("New_Password")}
                prefix={<IconUser />}
                style={{
                  borderColor: "transparent",
                  borderBottomColor: "#BCBCBC",
                  padding: "10px 5px",
                }}
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: t("Password_Once_More"),
                },
                ({ getFieldValue }) => ({
                  validator: (_, val) =>
                    confirmPasswordValidation(
                      getFieldValue("newPassword"),
                      val
                    ),
                }),
              ]}>
              <Input.Password
                placeholder={t("Confirm_Password")}
                prefix={<IconUser />}
                style={{
                  borderColor: "transparent",
                  borderBottomColor: "#BCBCBC",
                  padding: "10px 5px",
                }}
              />
            </Form.Item>
            <Form.Item>
              <StyledButton
                loading={isLoading}
                htmlType="submit"
                width={"w-100"}>
                {t("Submit_submit")}
              </StyledButton>
            </Form.Item>
          </Form>
        </FpBox>
      </FPContainer>
      <FooterContainer>
        <LandingFooter isDark />
      </FooterContainer>
    </>
  );
};

export default ResetPassword;
