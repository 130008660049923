export const jobType = [
  {
    id: 1,
    text: "Full Time",
  },
  {
    id: 2,
    text: "Part Time",
  },
  {
    id: 3,
    text: "Fixed",
  },
  {
    id: 4,
    text: "Remote",
  },
  {
    id: 5,
    text: "Contract",
  },
];
