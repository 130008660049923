import { Col, Row } from "antd";
import { routes } from "constants/routes";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { StyledLink } from "./StyledLink";

type TLandingFooterProps = {
  hasBorder?: boolean;
  isDark?: boolean;
};

const LandingFooter = ({ hasBorder = false, isDark }: TLandingFooterProps) => {
  const { t } = useTranslation("translation");
  return (
    <LandingFooterWrap
      hasBorder={hasBorder}
      className={`${isDark ? "dark" : ""}`}>
      <FooterInnerWrap>
        <Row align="middle" justify="center" gutter={{ xs: 15, lg: 41 }}>
          {/* <Col>
            <StyledLink to={routes.termsOfService}>{t("Term_of_Service")}</StyledLink>
          </Col> */}
          <Col>
            <StyledLink to={routes.privacyPolicy}>
              {t("Privacy_policy")}
            </StyledLink>
          </Col>
          {/* <Col>
            <StyledLink to={routes.cookiesSettings}>{t("Cookie_setting")}</StyledLink>
          </Col>
          <Col>
            <StyledLink to={routes.copyRightPolicy}>{t("Copyright_policy")}</StyledLink>
          </Col> */}
        </Row>
        <CopyRightText className={`${isDark ? "dark" : ""}`}>
          Copyright &copy; {moment().format("YYYY")} Mirai.com
        </CopyRightText>
      </FooterInnerWrap>
    </LandingFooterWrap>
  );
};

export const LandingHeaderHeight = 87;
export const LandingFooterHeight = 151;

interface ILandingFooterWrapProps {
  hasBorder?: boolean;
}

export const LandingFooterWrap = styled.footer<ILandingFooterWrapProps>`
  height: ${LandingFooterHeight}px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: ${({ hasBorder }) =>
    hasBorder ? "0.8px solid #BCBCBC" : "none"};
  padding: 0 15px;

  &.dark {
    a {
      color: #fff;
    }
  }
`;

export const CopyRightText = styled.div`
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.02em;
  color: #17202a;
  margin-top: 12px;

  &.dark {
    color: #fff;
  }
`;

export const FooterInnerWrap = styled.div`
  text-align: center;
  padding: 0 5px;
`;

export default LandingFooter;
