import React from "react";

const IconUserPlus = () => {
  return (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.33333 14.667C11.907 14.667 14 12.574 14 10.0003C14 7.42666 11.907 5.33366 9.33333 5.33366C6.75967 5.33366 4.66667 7.42666 4.66667 10.0003C4.66667 12.574 6.75967 14.667 9.33333 14.667ZM9.33333 15.8337C3.83833 15.8337 0 18.7118 0 22.8337V24.0003H18.6667V22.8337C18.6667 18.7118 14.8283 15.8337 9.33333 15.8337ZM25.6667 4.16699H22.1667V0.666992H19.8333V4.16699H16.3333V6.50033H19.8333V10.0003H22.1667V6.50033H25.6667V4.16699Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconUserPlus;
