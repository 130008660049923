import React from "react";

const ApprovedUser = () => {
  return (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.45933 13.8373C12.0677 13.8373 14.189 11.716 14.189 9.1076C14.189 6.49919 12.0677 4.37793 9.45933 4.37793C6.85092 4.37793 4.72967 6.49919 4.72967 9.1076C4.72967 11.716 6.85092 13.8373 9.45933 13.8373ZM9.45933 15.0197C3.89015 15.0197 0 17.9367 0 22.1142V23.2966H18.9187V22.1142C18.9187 17.9367 15.0285 15.0197 9.45933 15.0197Z"
        fill="currentColor"
      />
      <path d="M16.8916 4.86973L19.7428 7.081L24.9996 1" stroke="currentColor" strokeWidth="2.2" />
    </svg>
  );
};

export default ApprovedUser;
