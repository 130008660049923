import { Form, message, Modal, Switch } from "antd";
import {
  activateConsultant,
  deactivateConsultant,
  IGetConsultantFilterRes,
} from "api/consultant";
import { ConfigCardTitle } from "components/ConfigCard";
import { consultantStatus } from "constants/consultantStatus";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";

interface IApprovedRequestActionProps {
  visible: boolean;
  onCancel: () => void;
  selected: IGetConsultantFilterRes;
  handleVerified: () => void;
}

const ApprovedRequestAction = ({
  visible,
  onCancel,
  selected,
  handleVerified,
}: IApprovedRequestActionProps) => {
  const { t } = useTranslation("translation");
  const status = consultantStatus.find(
    (item) => item.id === selected.currentStateId
  )?.text;
  const checkedSwitch = status && status === "Active" ? true : false;
  const switchStatus = status && status === "Active" ? t("active") : t("disabled");

  const { mutate: activate, isLoading: isActivating } = useMutation(
    activateConsultant,
    {
      onSuccess: () => {
        onCancel();
        handleVerified();
      },
      onError: (err: any) => {
        message.error(err.response?.data.Errors.Message);
      },
    }
  );

  const { mutate: deactivate, isLoading: isDeActivating } = useMutation(
    deactivateConsultant,
    {
      onSuccess: () => {
        onCancel();
        handleVerified();
      },
      onError: (err: any) => {
        message.error(err.response?.data.Errors.Message);
      },
    }
  );

  const onStatusChange = (checked: boolean) => {
    if (checked) {
      activate(selected.consultantId);
    } else {
      deactivate(selected.consultantId);
    }
  };

  return (
    <Modal onCancel={onCancel} visible={visible} footer={null} title={null}>
      <ConfigCardTitle>{selected?.name}</ConfigCardTitle>
      <Form.Item label={t("active_status") + ` ${switchStatus}`} colon={false}>
        <Switch
          checked={checkedSwitch}
          loading={isDeActivating || isActivating}
          onChange={onStatusChange}
        />
      </Form.Item>
    </Modal>
  );
};

export default ApprovedRequestAction;
