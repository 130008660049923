import { Card, Col, Row, Typography } from "antd";
import React from "react";

const PrivacyPolicy = () => {
  return (
    <Card>
      <Typography.Title level={1} style={{ textAlign: "center" }}>
        アカウント利用規約　２０２２年７月１日 Mirai international connect
        System 利用規約
      </Typography.Title>
      <Row>
        <Col style={{ textAlign: "justify" }}>
          本規約は２０２２年７月０１日より <br />
          本System利用にあたり規約に同意されたユーザーには 即時適用されます。 第{" "}
          <br />
          １条、はじめに １， Mirai international connect <br />
          Systemはインターネット上の応募者と求人者を結ぶサービスです。 <br />
          　　２，Mirai international connect <br />
          Systemのアカウントを保有するユーザー（以下Mirai international connect{" "}
          <br />
          System「ユーザー」といいます）と本サイトを利用し求人情報（登録支援機関、事業所、工場、施設、日本語学校等を以下「登録企業」といいます）のアクセスや面談調整を実施します。本サービスを利用する場合には、利用規約（以下「本規約」といいます）の全ての記載内容について同意して利用するものとする。本サイトを利用し応募者情報（以下「応募者」という）のアクセスや面談にて本サービスを利用する場合には、利用規約（以下「本規約」といいます）の全ての記載内容について同意して利用するものとする。{" "}
          <br />
          　　３、弊社（管理者）は、本サービスにおける掦載または個別の電子メールによる送信その他適切な　
          <br />
          　　　　方法によって、内容変更及び変更時期を周知することにより、変更できるものとします。変更後の本規約は、弊社（管理者）が別途定める場合を除いて、本サービス上に表示した後にユーザーが本サービスを利用した時点より効果を生じます。{" "}
          <br />
          　　４，本サービスには、本規約およびその他の規約等において、本サービスの利用条件が規定される場合があります。その他の規約等は名称の如何に関わらず本規約の一部を構成するものと　
          <br />
          　　　　　します。 　　５，本規約に定めがない事項は、Mirai <br />
          international connect <br />
          System利用規約が適用されるものとし、本規約と弊社が定めるその他の規約（Mirai{" "}
          <br />
          international connect System利用規約を含む） 　　　　
          <br />
          の内容が矛盾する場合には、本サービスに関しては、本規約が優先するものとします。{" "}
          <br />
          第 ２条、本サービスの利用許諾 １， <br />
          弊社（管理者）は、登録企業に対し、本規約の定める条件に従い、本サービスの譲渡不能かつ{" "}
          <br />
          非独占的な利用権を許諾します。 ２， <br />
          本規約は、将来開発される本サービスのアップグレード等に対する利用権を許諾するものでは{" "}
          <br />
          ありません。 ３， <br />
          弊社（管理者）は、登録企業及び応募者に通知することなく、本サービスの機能または使用を変更することがあります。{" "}
          <br />
          　　 第　３条、連絡・通知 <br />
          　　　　本サービスに関する問合せその他登録企業から弊社に対する連絡または通知および本規約{" "}
          <br />
          　　　　の変更に関する通知その他弊社（管理者）から登録企業に対する連絡または通知は、弊社の{" "}
          <br />
          　　　　定める方法で行うものとする。 <br />
          第　４条、本サービスの利用上の注意事項 １． <br />
          本サービスは、プロンプトにおける警告の有無にかかわらず、一定期間ごとにネットワークに接　　{" "}
          <br />
          続し通信を行う場合があります。その際には、別途通信料がかかります。
          <br />
          ２，
          <br />
          本サービスの表示および本サービスの動作に必要とされるプログラムデータおよび関連する
          <br />
          ドキュメントファイル等は、予告なく自動的に変更・更新される場合があります。
          <br />
          ３，
          <br />
          本サービスの実行中に圏外への移動などによってネットワークへの接続が出来ない場合や、建物内、ビル陰などで通信ができない場合には、一時的に本サービスの利用ができなくなる{" "}
          <br />
          ことがあります。 ４，
          <br />
          登録企業、応募者は、本サービスからのダウンロードその他の方法によりソフトウェア等を登録企業、応募者のコンピューター等の端末にインストールする場合には、登録企業、応募者の保有する情報の消滅もしくは改変または危機の故障、損傷等が生じないよう十分な注意を払うものとし、弊社（管理者）は登録企業、応募者に発生したかかる損害について一切責任を負わないものとします。なお、ダウンロード等を行う場合には別途通信料がかかる場合があります。
          <br />
          ５， <br />
          弊社（管理者）は、本サービス上に弊社または第三者の広告を表示することができるものとします。{" "}
          <br />
          第　５条、個人情報および利用履歴の取扱い １， <br />
          弊社（管理者）は、個人情報を「プライバリシーポリシー」に基づき、適切に取り扱うものとする。{" "}
          <br />
          ２，弊社（管理者）は、登録企業、応募者による本サービスの利用記録および個人情報を集計・
          <br />
          　　　　分析し、個人を識別・特定できないように加工した統計データ等を作成し、当該統計データ等{" "}
          <br />
          　　　　につき何らの制限なく利用（登録企業、応募者および第三者への提案、市場調査、新サービス{" "}
          <br />
          　　　　の開発を含みますがこれらに限りません。）することができるものとし、登録企業、応募者はこれ{" "}
          <br />
          　　　　を予め承諾するものとします。
          <br />
          　　３，弊社（管理者）は、別途弊社が収集したユーザーからのアンケート結果、利用履歴、登録企業{" "}
          <br />
          　　　　等の利用実態（弊社で確認できるやりとり、その他登録企業からの報告内容を含みますがこれらに限りません。）をもとにした集計結果など、登録企業の効果情報を本サービス上に掲示できるものとし、登録企業はこれを予め承諾するものとします。
          <br />
          第　6条、企業アカウント、応募者アカウント登録等 <br />
          　　１，本サービスを利用できるのは、本サービスに登録を希望する企業、事業所、工場、施設、日本語学校等（（　以下「登録希望企業　」といいます）　　応募者等（以下「登録希望応募者」といいます））であって、登録希望企業に所属するユーザーおよび登録希望応募者に本サービスを利用する権限を付与している登録希望企業および登録規模応募者に限るものとします。ただし、弊社が別に認めたものを除きます。
          <br />
          ２， <br />
          登録希望企業および登録希望応募者は、本規約の内容に同意した上で、弊社所定の方法に{" "}
          <br />
          より、企業アカウント登録、応募者アカウント登録（以下「アカウント登録」といいます。）を行うものとします。アカウント登録を行うと登録を行ったユーザーの管理権限を持つ企業メンバーとする登録希望企業の企業アカウントの管理画面が生成されます。なお、すでに当該登録企業の{" "}
          <br />
          アカウントが存在する場合は、当該企業アカウントの管理権限を持つ企業メンバーの承認を経ることによって、その登録企業アカウントに紐づく別の企業メンバーとして登録することが出来ます。その場合の企業メンバーには管理権限は付与されませんが、管理権限は別途手続きをすることによって付与・移管することができます。企業アカウントに紐づける企業メンバーの数には一定の制限を設ける場合があります。
          <br />
          　　３，弊社（管理者）が弊社の基準に従って、登録希望企業、登録希望応募者の登録可否を判断　{" "}
          <br />
          　　　　し、弊社（管理者）が登録を認める場合には、その旨をアカウント登録を行ったユーザーに通{" "}
          <br />
          知した時点で登録企業、登録応募者になります。登録可否の判断は弊社（管理者）の単独の裁量に基づくものであり、弊社（管理者）が登録を拒否したことについて何らの責任を負うものではありません。{" "}
          <br />
          　　４，前項の登録時点で、本規約に従った本サービスの利用契約が登録企業、登録応募者と弊社の間に成立し、登録企業、登録応募者は本サービスを弊社（管理者）の定める方法で利用することが出来るようになります。{" "}
          <br />
          ６， <br />
          登録希望企業、登録希望応募者は、登録するにあたり、真実、正確かつ最新の情報を弊社（管理者）に提供しなければなりません。{" "}
          <br />
          ７， <br />
          弊社（管理者）は、登録企業、登録応募者に対して新サービスの告知、広告配信、サービス運営上の事務連絡、その他情報の提供を行う場合があります。ただし、登録企業、登録応募者が情報の提供を希望しない場合は、本サービスに関するサービスに必要な場合を除いて、情報の提供を行わないものとします。
          <br />
          第　７条、アカウント登録の不承諾 <br />
          　　　弊社（管理者）は、次の各号のいずれかに該当する場合は、弊社の判断によって、登録希望企業、登録希望応募者のアカウント登録を承認しないことがあります。なお、弊社（管理者）が認める場合は承諾することもあります。弊社（管理者）は、これについて一切の責任を負わず、また登録を承諾する理由を登録希望企業、登録希望応募者に説明する義務を負わないものとします。
          <br />
          　　◇前項第２項の方法によらずにアカウント登録を行った場合。
          <br />
          　　◇企業メンバーが、登録企業に所属していないことが明らかになった場合およびその判断に至った場合。{" "}
          <br />
          　　◇弊社（管理者）に提供された登録事項の全部または一部につき虚偽、誤記又は記載漏れがあった場合{" "}
          <br />
          　　◇実在しない又は事業を行っていない場合。 <br />
          　　◇登録希望企業に関しては法人格を有してない場合。（但し、弊社（管理者）が特に認める場合を除く）{" "}
          <br />
          　　◇海外法人であって日本国内に現地法人がない場合。（但し、弊社（管理者）が特に認める場合を除く）{" "}
          <br />
          　　◇事業内容が法令または弊社もしくは登録希望企業が所属する業界団体の内部規則に違反する場合。
          <br />
          　　◇事業内容が公序良俗に反する場合、またはマルチ商法、悪徳商法、ねずみ講など社会的に問題となり得る事業を行っている場合。{" "}
          <br />
          　　◇風俗営業等の規制及び義務の適正化等に関する法律に定められる風俗営業、性風俗営業関連特殊営業または接客業務受託営業を営む場合。
          <br />
          　　◇反社会的活動を行っている場合その他反社会的勢力に該当する場合もしくはこれらの企業と{" "}
          <br />
          　　　 関連がある場合。 <br />
          　　◇出会い系サイト、アダルトサイト、ライブチャットサイト等を運営している場合。
          <br />
          　　◇許認可を必用とされる事業で許認可を取得してない場合。 <br />
          　　◇過去において本サービスを通じて弊社（管理者）と採用の前後を問わずトラブルを起こした場合。{" "}
          <br />
          　　◇弊社（管理者）が要請した資料の提出または質問の回答を拒んだ場合。{" "}
          <br />
          　　◇弊社（管理者）、他の登録企業（ユーザー）、登録応募者（ユーザー）または第三者に損害を生じ{" "}
          <br />
          　　　　させるおそれのある目的または方法で本サービスを利用しようとする場合。
          <br />
          　　◇手段を問わず本サービスの運営を妨害した場合。 <br />
          　　◇登録するメールアドレスが、無料で取得できるメールアドレス、携帯電話のメールアドレスの場合は弊社（管理者）への「誓約書の同意」および通知確認および企業証明・身分証明が確認できない場合、その他弊社（管理者）が不適切と判断するメールアドレスである場合。{" "}
          <br />
          　　◇登録希望企業に於いては、登録する電話番号が固定電話以外の電話（携帯電話を含む。）の番号である場合。{" "}
          <br />
          　　◇弊社（管理者）の判断において登録できないとされる判断をした場合。{" "}
          <br />
          　　◇前各号への該当を避ける目的で別会社を利用して登録を試みていると推測される場合。{" "}
          <br />
          　　◇その他、弊社（管理者）が不適切と判断する行為。 第　８条、解約{" "}
          <br />
          　　１，登録企業が本サービスを解約を希望する場合には、登録企業は弊社所定の方法により、弊社（管理者）に登録企業の解約の申出を行うものとします。解約すると当該登録企業は本サービスを解約したことになります。なお、企業メンバーは当該企業アカウントの機能は全て使えなくなります。{" "}
          <br />
          　　２，弊社は登録企業が次の各号に掲げるいずれかの行為を行った場合には、弊社（管理者）の判断によって、当該登録企業のアカウントを強制的に本サービスの利用を一時的に停止し、もしくは解約して本サービスの利用をお断りすることがあります。
          <br />
          　◇第６条第２項の方法によらずに企業アカウント登録を行ったことが明らかになった場合。
          <br />
          　◇前条各号に該当していたことが判明した場合または現に同項各号に該当する場合。
          <br />
          　◇反社会的活動を行っている場合その他反社会的勢力に該当する場合もしくはこれらの企業と関連がある場合。
          <br />
          ◇第１４条に該当刷る場合。 　◇第１７条各号に該当する行為を行った場合。
          <br />
          　◇登録された住所に対して弊社（管理者）が送付した資料等が宛先不明で返送された場合。その他登録企業の所在が確認できない場合。
          <br />
          　◇支払停止もしくは支払不能となり、または破産、民事再生手続開始、会社更生手続開始、会社整理開始、特別清算開始もしくはこれに類する手続の開始の申立てがあった場合。
          <br />
          　◇自ら振出し、もしくは引受けた手形または小切手につき、不渡りの処分を受けた場合。
          <br />
          　◇差押、仮差押、仮処分、強制執行または競売の申立てがあった場合。
          <br />
          　◇租税公課の滞納処分を受けた場合。
          <br />
          　◇１２か月以上本サービスの利用がない場合。
          <br />
          ◇利用料金が発生し支払を遅滞した場合、その他本規約に違反した場合。
          <br />
          ◇弊社（管理者）が別途定める審査基準において登録を取り消すとする場合。
          <br />
          　◇本規約またはその他の規約等に違反した場合。
          <br />
          　　◇企業メンバーが登録企業に所属していないことが明らかになった場合。
          <br />
          　　◇その他弊社（管理者）が不適切と判断した場合。
          <br />
          　　３，前項に基づき、解約された場合、登録企業は、弊社に対して負っている債務の一切について{" "}
          <br />
          　　　　期限の利益を失い、直ちに弊社（管理者）に対して全ての債務を支払わなければなりません。
          <br />
          　　４，弊社（管理者）は、本条に基づき弊社（管理者）が行った行為により登録企業、登録応募者に生じた損害について一切の責任を負いません。
          <br />
          ５、本条に基づき登録企業が解約した場合、登録企業は弊社（管理者）の指示に基づき、弊社（管　理者）から提供を受けた本サービスに関連するソフトウェア、マニﾕアルその他の物につき、返還、廃棄その他の処分を行うものとする。本システムにおいて得た応募者の個人情報も含まれる、また知り得た情報の写しを第三者に提供することもしてはならない。
          <br />
          第　９条、企業メンバーの管理簿 <br />
          　　１，登録企業は、自己に所属する一人以上の企業メンバーを企業アカウントの管理者として登録しなければならず、当該{" "}
          <br />
          企業メンバーが自己に所属しなくなったときには、自己に所属する企業メンバーを管理者として変更しなければならないものとします。{" "}
          <br />
          ２，登録企業は、前項の企業メンバーのほか弊社（管理者）が別途指定する情報（法人名、法人情{" "}
          <br />
          報、担当者氏名、法人メールアドレスなどを含みますがそれに限りません）を登録しなければならないものとし、当該企業は、登録企業の責任において最新の情報に更新するものとします。
          <br />
          なお、前項の企業メンバー、本項の情報をあわせて登録情報といいます。
          <br />
          　　３，登録企業は、自己の登録情報の不正利用の防止に努めるとともに、その管理について一切の責任を持つものとします。
          <br />
          　４，登録情報が第三者に利用されたことによって生じた損害等につきましては、弊社（管理者）はいかなる責任も負いません。なお、第三者に利用されていることが判明した場合には、登録企業は直ちにその旨を弊社（管理者）に通知するとともに、弊社（管理者）からの指示に従うものとします。
          <br />
          第１０条、企業アカウントの保有 <br />
          　　１，登録企業は１法人につき、１つの企業アカウントを保有するものとし、企業アカウントには１人以上の企業メンバーを紐づける必要があります。なお、管理権限を付与された企業メンバーは当該登録企業アカウントに本サービス所定の方法で紐づける形で、その他のユーザーを当該登録企業アカウントの企業メンバーとして登録することができます。また、登録企業に紐づけられたすべての企業メンバーによる本サービスの利用行為は、当該登録企業の行為とみなします。
          <br />
          　　２，登録企業はいかなる場合においても、企業アカウントを第三者に譲渡又は貸与することはできません。
          <br />
          第１１条、利用環境の整備 １，
          <br />
          登録企業は、本サービスを利用する為に必要なあらゆる機器、ソフトウェア、通信手段を自己の責任費用において、適切に整備するものとする。
          <br />
          ２，
          <br />
          登録企業は自己の利用環境に応じて、コンピューターウイルスの感染の防止、不正アクセスおよび情報漏洩の防止等のセキュリティ対策を講じるものとします。
          <br />
          　　３，弊社（管理者）は登録企業の利用環境について一切関与せず、また一切の責任を負いません。
          <br />
          第１２条、登録企業の責任 <br />
          　　１，登録企業は、登録企業自身の自己責任において本サービスを利用するものとし、本サービスを利用してなされた一切の行為およびその結果について一切の責任を負います。また、登録企業は、企業アカウントに紐づけた企業メンバーに本規約を遵守させる責任を負うものとします。
          <br />
          ２、登録企業は、登録情報に変更はあった場合は、遅滞なく弊社（管理者）の定める方法により、当該変更事項を弊社に通知し、弊社（管理者）から要求された資料を提出するものとします。
          <br />
          ３，
          <br />
          弊社（管理者）は、登録情報を確認する必要があると判断した場合は、登録企業に対して登記簿謄本その他弊社（管理者）が必要と判断する資料の提出を求めることができるものとします。
          <br />
          　　４，本サービスを利用して登録企業が投稿した情報（特定技能、高度人材、留学生用の求人情報を含みますがそれに限られません。以下「投稿情報」といいます）に関する責任は、登録企業自身にあります。弊社（管理者）は投稿情報の内容について一切の責任を負いません。
          <br />
          　　５，登録企業は、応募があった応募者ユーザーに対して、弊社所定の期間内に適切に対応するものとします。
          <br />
          　　６，登録企業が他人の名誉を毀損した場合、プライバシー権を侵害した場合、許諾なく第三者の個人情報を開示した場合、著作権法（昭和４５年法律第４８号）に違反する行為を行った場合その他他人の権利を侵害した場合には、当該登録企業は自身の責任において解決しなければならず、弊社（管理者）は一切の責任を負いません。
          <br />
          　　７，登録企業は、登録情報および投稿情報について弊社（管理者）に保存義務はないことを認識し、必要な情報については適切にバックアップをとるものとします。
          <br />
          　　８，登録企業は、登録企業、企業メンバー、所属する役員または従業員等が総会屋、暴力団等もしくはそれらの構成員またはそれらに準ずる反社会的勢力（以下「反社会的勢力」といいます）と一切の関係も無く名目を問わず資金提供その他の取引を行うものではないこと、および登録企業、所属する役員または従業員等が関係する企業、事務所もしくは団体等またはそれらの役員、従業員が反社会的勢力と一切関係が無く、名目を問わず資金提供その他の取引を行うものではないことを保証するものとします。
          <br />
          第１３条、本サービスの内容 <br />
          　　　登録企業に対して以下のサービスを提供します。
          <br />
          ◇特定技能外国人、高度人材、留学生の求人、留学に係る情報アクセス・管理・面接依頼を、弊社（管理者）を通して世界の応募者を紹介するサービスです。
          <br />
          ◇選考一覧管理、登録企業ユーザーと応募者ユーザーが本サービス上において、メッセージを送受信し、双方をマッチングに案内するサービスです。
          <br />
          　◇その他、弊社（管理者）が定めるサービス <br />
          （ただし、別途料金の支払いが必要とされる場合があります）
          <br />
          　◇弊社（管理者）は登録企業向けに海外および国内の外国人材を紹介するBusiness{" "}
          <br />
          to Business データーベースとなるため登録企業が直接Mirai international{" "}
          <br />
          connect <br />
          System応募者ユーザーとやり取りし採用はできないものとする。必ず弊社（管理者）を通してからの紹介となる。{" "}
          <br />
          第１４条、禁止事項 <br />
          　　登録企業および企業メンバーは、本サービスの利用にあたり、次に掲げる行為を行ってはならないものとします。禁止事項に違反した場合には、強制解除、利用停止または投稿情報の全部もしくは一部の削除等の不利益な措置を採ることがあります。弊社（管理者）は、本項に基づき弊社（管理者）が行った措置に関して登録企業に生じた損害について一切の責任を負いません。
          <br />
          　◇弊社（管理者）、他の登録企業、ユーザーもしくは第三者の著作権、商標検等の知的財産権を{" "}
          <br />
          害する行為、または侵害するおそれのある行為。
          <br />
          　◇弊社（管理者）、他の登録企業、ユーザーもしくは第三者を不当に差別もしくは誹謗中傷し、第三者への不当な差別を助長し、またはその名誉もしくは信用を毀損する行為。
          <br />
          　◇本サービスに関し利用し得る情報を改ざんする行為。
          <br />
          　◇本サービスの情報からユーザーの連絡先等を特定し、本サービスを通じずに一方的に連絡をする行為。
          <br />
          　◇本サービスを利用してユーザーとの対話中に、一方的に対応を修了する行為。
          <br />
          　◇該当ユーザーの同意を得ずに、該当ユーザーの個人情報を第三者に漏洩する行為。
          <br />
          　◇他の登録企業、ユーザーまたは弊社（管理者）に対する、営業目的でのメールの送信、その他{" "}
          <br />
          営業行為。
          <br />
          ◇セミナーの告知、アンケートの回答者またはモニターの募集等、本システムの求人以外の目的でメール、資料等を該当ユーザーに対して送信または送付する行為。
          <br />
          　◇本システムに示した労働条件と異なる労働条件でユーザーを雇用する行為。
          <br />
          　◇同時に複数多量の求人情報やメッセージを掲載、送信しようとする行為。
          <br />
          　◇弊社（管理者）、他の登録企業、ユーザーまたは第三者に損害を生じさせるおそれのある目的または方法で本サービスを利用し、または利用しようとする行為。
          <br />
          　◇自分以外の人物を名乗ったり、代表権や代理権がないにもかかわらずあるものと装ったり、または他の人物や組織と提携、、協力関係にあったと偽って本サービスを利用する行為。
          <br />
          　◇ １ 法人が複数の企業アカウントを保有する行為または複数法人が １{" "}
          <br />
          つの企業アカウントを共同して保有する行為。ただし、弊社（管理者）が別に認めたものを除く。
          <br />
          　◇法人または法人に所属するMirai international connect <br />
          Systemユーザー以外が本サービスを利用する行為。ただし、弊社が別に認めたものを除く。
          <br />
          　◇弊社（管理者）の設備に蓄積された情報を不正に書き換え、または消去する行為。
          <br />
          　◇ウイルス等の有害なコンピュータープログラム等を送信または消去する行為。
          <br />
          　◇本サービスを修正、変更、改変、リバース・エンジニアリング、逆コンパイル、逆アセンブルなどをする行為。
          <br />
          　◇弊社（管理者）が定める一定のデータ容量以上のデータを、本サービスを通じて送信する行為。
          <br />
          　◇弊社（管理者）または第三者のサーバーに負担をかける行為、もしくは本サービスの運営やネットワーク・システムに支障を与える、またはこれらのおそれのある行為。
          <br />
          　◇その行為が前各号のいずれかに該当することをしりつつ、その行為を助長する目的でリンクを貼る行為。
          <br />
          　◇犯罪行為に関する行為。
          <br />
          　◇反社会的勢力または反社会的活動に関する行為。
          <br />
          　◇本サービスの運営を妨害するおそれのある行為。
          <br />
          　◇法令、公序良俗または本規約、登録企業が所属する業界団体の内部規に違反し、または第三者の権利を侵害すると弊社が判断する行為。
          <br />
          　◇その他、弊社（管理者）が不適切と判断する行為。
          <br />
          第１５条、サービスの変更等 <br />
          　　弊社（管理者）は、弊社（管理者）の都合により、本サービスをいつでも任意の理由で追加、変更、中断、終了することができます。
          <br />
          第１６条、利用料金
          <br />
          　　１，登録企業は弊社（管理者）が指定する支払方法によって、所定の料金およびこれに対する消費税相当額を支払うものとします。振込手数料その他支払に必要な費用は登録企業の負担とします。なお、事前に支払う場合は、当該支払いが確認できない場合は、本サービス　の提供を中断、終了する場合ばあります。
          <br />
          　２，前項の料金は、前条に規定される中断または終了その他のいかなる理由によっても消滅しまたは減額もしくは免除されないものとします。なお、すでに支払い済みの料金に関してもいかなる理由によっても返金等は行わないものとします。
          <br />
          第１７条、遅延利息 <br />
          　　１，登録企業が料金の支払いを支払期日より遅延した場合には、支払期日の翌日から支払い日の前日までの日数について年利１４．６％の割合で計算した額の遅延利息金を弊社（管理者）に支払うものとします。
          <br />
          　　２，前項の支払いに必要な振込手数料その他の費用は、当該登録企業が負担するものとします。
          <br />
          　　３，登録企業が支払いを支払期日より遅延したと弊社（管理者）が認めた場合には、弊社（管理者）は、当該登録企業に通知することなく、提供中の全てのサービスの利用を停止することができるものとします。
          <br />
          第１８条、権利帰属 <br />
          　　本サービスに関する所有権および知的財産権は全て弊社（管理者）または弊社にライセンスを許諾している者に帰属しており、本規約に定める登録に基づく本サービスの利用許諾は、本サービスに関する弊社（管理者）または弊社（管理者）にライセンスを許諾している者の知的財産権の利用許諾を意味するものではありません。登録企業は、いかなる理由によっても弊社（管理者）または弊社（管理者）にライセンスを許諾している者の知的財産権を侵害するおそれのある行為（逆アセンブル、逆コンパイル、リバースエンジニアリングを含みますが、これに限定されません）をしないものとします。
          <br />
          第１９条、投稿情報の利用許諾等 １， <br />
          本サービスを利用して投稿された投稿情報の権利（著作権および著作者人権等の周辺権利）は、投稿した登録企業に帰属します。
          <br />
          ２， <br />
          登録企業には弊社（管理者）に対し、投稿情報（登録企業のロゴ、送受信したメッセージ、登録した会社概要。）を弊社（管理者）の宣伝・プロモーション等の広報活動および本サービスの円滑な提供、宣伝、改良、メンテナンスに必要な範囲内で、全世界で無制限、無償かつ非独占的に利用することを許諾し、またはそのために必要な措置を講ずるものとします。なお、登録企業は弊社（管理者）および弊社（管理者）の指定する者に対して著作者人格権を行使しないものとする。
          <br />
          ３， <br />
          弊社（管理者）は、投稿情報を運営上必要であると弊社（管理者）が判断する場合は、利用規約の期間および掲載期間等にかかわらず、任意の期間保存し、利用できるものとし、登録企業はこれに同意するものとします。
          <br />
          ４，
          <br />
          前項による情報の保存および利用については、弊社は何ら義務を負うものではなく、弊社はいつでもこれらの情報を削除できるものとします。
          <br />
          ５，弊社（管理者）は本条に基づき弊社（管理者）が行った措置によって登録企業に生じた損害を一切の責任を負いません。
          <br />
          第２０条、秘密保持 　<br />
          　弊社（管理者）および登録企業は、本規約または本サービスに関連して、相手方より書面、口頭若しくは記録媒体等により提供若しくは開示されたか、または知りえた、相手方に関する技術、営業、業務、財務または組織に関する全ての情報{" "}
          <br />
          （ただし、公知の事実を除く）
          <br />
          を本サービスの目的のみに利用するとともに、相手方の書面による承諾ないし第三者に相手方の秘密情報を提供、開示または漏洩しないものとします。
          <br />
          第２１条、損害賠償および責任の制限 <br />
          １，登録企業は、本契約に違反することにより、または本サービスの利用に関連して弊社（管理者）に損害を与えた場合、弊社（管理者）に対してその損害を賠償しなければなりません。
          <br />
          　２，登録企業が、本サービスに関連して他の登録企業、ユーザー、その他の第三者からのクレームを受けまたはそれらの者との間で紛争を生じた場合には、直ちにその内容を弊社（管理者）に通知するとともに、登録企業の費用と責任において当クレームまたは紛争を処理し、弊社からの要請に基づき、その結果を弊社（管理者）に報告するものとします。
          <br />
          　３，登録企業による本サービスの利用に関連して、弊社が他の登録企業その他の第三者から権利侵害その他の理由により何らかの請求を受けた場合は、登録企業は当該請求に基づき弊社（管理者）が当該第三者に支払いを余儀なくされた金額を賠償しなければなりません。
          <br />
          　４，弊社（管理者）の責めに帰すべき事由により登録企業が損害を被った場合には、直接の結果として現実に被った通常の損害に限り、賠償責任を負うものとします。ただし、弊社（管理者）の損害責任は、損害賠償の事由が発生した時点から遡って過去１年間の期間に当該登録企業から現実に受領した料金の総額を上限とします。
          <br />
          第２２条、権利譲渡等 <br />
          　１，登録企業は、本規約に定める場合を除くほか、本規約に基づくいかなる権利または義務も第三者に移転、譲渡その他の処分を行うことができません。
          <br />
          　２，弊社（管理者）は本サービスにかかる事業を第三者に譲渡した場合には、当該事業譲渡に伴い本規約上の地位、本規約に基づく権利および義務ならびに登記企業の情報その他一切の情報を当該事業譲渡の譲受人に譲渡することができるものとし、登録企業は、当該事業譲渡につき、予め同意するものとします。なお、当該事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
          <br />
          第２３条、免責事項 <br />
          　１，弊社（管理者）は、本サービスの内容につき如何なる保証を行うものではありません。さらに、登録企業が弊社（管理者）から直接または間接に本サービスまたは他の登録企業に関する情報を得た場合であっても、弊社（管理者）は登録企業に対して本規約において規定されている内容を超えて如何なる保証を行うものではありません。
          <br />
          　２，登録企業は、本サービスを利用することが、登録企業に適用のある法令、業界団体の内部規律等に違反するか否かを自己の責任と費用に基づいて調査するものとし、弊社（管理者）は、登録企業による本サービスの利用が、登録企業に適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません。
          <br />
          　３，弊社（管理者）は、弊社（管理者）による本サービスの提供の中断、停止、利用不能または変更、登録企業のメッセージまたは情報の消滅または消失、登録企業の解約、本サービスの利用によるデータの消失または機器の故障もしくは損傷、その他本サービスに関して登録企業が被った損害につき、賠償する責任を一切の責任を負わないものとします。
          <br />
          　４，登録企業は、自己の責任において本サービスを利用するものとし、本サービスの利用による情報の消除または消失、機器の故障または損傷、Mirai{" "}
          <br />
          international connect <br />
          Systemユーザーその他第三者との間の紛争による損害等、本サービスの利用によって登録企業が被った損害については、弊社（管理者）は一切の責任を負わないものとする。
          <br />
          　５，アクセス過多、その他予期せぬ要因で表示速度の低下や障害等によって生じた損害について、弊社（管理者）如何なる責任も負いません。
          <br />
          　６，弊社（管理者）は、投稿情報を監視したり、保存する義務を負いません。
          <br />
          　７，弊社（管理者）は、投稿情報の合法性、道徳性、信頼性、正確性について責任を負いません。投稿情報が、当該登録企業の内部規則等に適合することについても責任を負いません。
          <br />
          　８，弊社（管理者）は、次に掲げる場合には、当該投稿情報の内容を閲覧したり、保存したり、第三者に開示すること（以下、本項において「閲覧等」といいます）ができるものとします。弊社（管理者）は、それによって生じた如何なる損害についても一切の責任を負いません。
          <br />
          　◇弊社（管理者）は、投稿情報を投稿した登録企業の本サービスに登録した連絡先に充てて閲覧等の同意を求める連絡をした場合であって、次の各号に掲げるいずれかに該当するとき。
          <br />
          　　　＊当該登録企業が閲覧等に同意したとき。
          <br />
          　　　＊弊社（管理者）が、閲覧等の同意を求める連絡をしてから７日以内に、これを拒否する旨の当該登録企業の回答が弊社（管理者）の到達しなかったとき。ただし、緊急止むを得ない事由が生じたときは除く。
          <br />
          　◇本サービスの技術的不具合の原因を解明し、解消するため必要な場合。
          <br />
          　◇裁判所や警察などの公的機関から、法令に基づく正式な照会を受けた場合。
          <br />
          　◇本規約に違反する行為またはそのおそれのある行為が行われ、投稿情報の内容を確認する必要が生じたと弊社（管理者）が判断した場合。
          <br />
          　◇人の生命、身体および財産などに差し迫った危険があり、緊急の必要性があると弊社（管理者）が判断した場合。
          <br />
          　◇その他本サービスを適切に運営するために必要が生じた場合。 <br />
          　８，弊社（管理者）は、本規約またはその他の規約等に違反する行為またはそのおそれのある行為が行われたと信じるに足りる相当な理由があると判断した場合には、当該{" "}
          <br />
          行為を行った登録企業の強制解約処分および投稿情報の全部もしくは一部の削除等を行う場合がありますが、それによって生じた如何なる損害についても、一切の責任を負いません。{" "}
          <br />
          １０，本規約の定めにかかわらず、いかなる場合においても弊社（管理者）は、本サービスに関して次に揚げる損害については一切の責任を負いません。
          <br />
          　◇弊社（管理者）の責に帰すべからざる事由から生じた損害。 <br />
          　◇弊社（管理者）の予見の有無に関わらず、特別な事情から生じた損害。
          <br />
          　◇サーバーのメンテナンスなど本サービスの提供にあたって必要不可欠な行為によって生じた損害。
          <br />
          １１，本サービスから他のサービスへのリンクまたは他のサービスから本サービスへのリンクが提供されている場合でも、弊社（管理者）は、本サービス以外のサービスおよびそこから得られる情報に関していかなる理由に基づいても一切の責任を負わないものとします。
          <br />
          第２４条、本規約およびの他の規約等の有効性 <br />
          　１，本規約およびその他の規約等の規定の一部が法令に基づいて無効と判断されても、本規約およびその他の規約等のその他の規定は有効とします。
          <br />
          　２，本規約およびその他の規約等の規定の一部がある登録企業のとの関係で無効とされ、または取り消された場合でも、本契約およびその他の規約等はその他の登録企業との関係では有効とします。
          <br />
          第２５条、本規約またはその他の規約等違反行為等への対処 １，
          <br />
          本規約またはその他の規約等に違反する行為を発見した場合には、お問い合わせフォームから通報してください。ただし、個別のサービスにおいて、特に弊社（管理者）に通報するためのリンクが設けられている場合には、当該リンク先から通報してください。
          <br />
          ２，
          <br />
          登録企業が本規約またはその他の規約等に違反したと認められる場合、その他弊社（管理者）が必要と認める場合は、弊社（管理者）は当該登録企業に対し以下の対処を講ずることがあります。
          <br />
          　◇本規約またはその他の規約等に違反する行為等を止め、同様の行為を繰り返さないことを要求する。
          <br />
          　◇投稿情報の自発的削除・訂正を求めること。
          <br />
          　◇投稿情報の全部もしくは一部を削除しまたは閲覧できない状態（非公開）にすること。
          <br />
          　◇強制解約処分とすること。
          <br />
          　３，登録企業は、弊社（管理者）が行った本規約またはその他の規約等に違反する行為等への対処について、異議を申し立てることができないものとする。
          <br />
          第２６条、有効期限 <br />
          　利用契約は、登録企業については第６条に基づく登録が完了した日から当該登録企業の登録が{" "}
          <br />
          本規約等および解約された日まで、弊社（管理者）と登録企業との間で有効に存続するものと　　　
          <br />
          します。 第２７条、準拠法および管轄裁判所 <br />
          　１，本規約の準拠法は、日本法とします。
          <br />
          　２，本サービスまたは本規約に関して登録企業との間で疑義または争いが生じた場合には、誠意をもって協議することとしますが、それでもなお解決しない場合には、名古屋地方裁判所または名古屋簡易裁判所を第一審の専属的合意管轄裁判所とします。
          <br />
          本契約同意書 Mirai international connect <br />
          System　利用規約を読み、利用規約を遵守し本規約に同意します。
          <br />
          本システムでの登録および検索においては、費用は発生いたしません。
          <br />
          求人募集等において（本規約１３条）応募者の採用にいたった時に費用が発生いたします。
          <br />
          ・採用手数料明細は、本規約に同意されました業種別にご案内させていただきます。
          <br />
          Mirai international connect <br />
          Systemにアクセスし契約することを同意します。 <br />
          　※本契約に同意した場合、下記ボックスに　レ　してください。
          <br />
          　□　本契約同意書に同意いたしました。 運営企業 <br />
          岐阜平成協同組合およびＭｉｒａｉ　Ｉｎｔｅｒｎａｔｉｏｎａｌ合同会社との共同運営をしております。
          <br />
          岐阜平成協同組合は、登録支援機関 <br />
          Ｍｉｒａｉ　Ｉｎｔｅｒｎａｔｉｏｎａｌ合同会社は、有料職業紹介事業{" "}
          <br />
          弊社（管理者）の管理者は、ＣＯＮＮＥＣＴが行います。 <br />
          http://www.connect0901.com/ <br />
        </Col>
      </Row>
    </Card>
  );
};

export default PrivacyPolicy;
