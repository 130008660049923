import axios from "axios";

export interface IFreelancersRes {
  id: string;
  name: string;
  email: string;
  phone: string;
  location: string;
  occupation: string;
  configuration: {
    id: string;
    isCityVisible: boolean;
    isCountryVisible: boolean;
    isFirstNameVisible: boolean;
    isLastNameVisible: boolean;
    isPriceVisible: boolean;
    isStateVisible: boolean;
  };
}

type TGetFreelancerParams = {
  isAscending?: boolean;
  keyword?: string;
};
export interface ICandidateProfileDetailData {
  candidateId: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  personalDetail: {
    gender: string;
    dateOfBirth: string;
    maritalStatus: string;
    religion: string;
    languageLevelIds: string[];
    occupation: string;
    country: string;
    state: string;
    city: string;
    otherAddress: string;
    houseNumber: string;
    language: string;
    paymentTypeId: number;
  };
  educationDetail: {
    school: string;
    startDate: string;
    endDate: string;
    degree: string;
    fieldOfStudy: string;
  };
  workExperienceDetail: [
    {
      companyName: string;
      location: string;
      country: string;
      position: string;
      startDate: string;
      endDate: string;
      description: string;
    }
  ];
  otherDetail: {
    passportNumber: string;
    residentCard: string;
    visaType: string;
    visaValidity: string;
    appliedFor: string;
    applicationPeriod: number;
    expectedSalary: number;
    paymentTypeId: number;
    isStudent: boolean;
  };
  skills: skills[];
}
export interface skills {
  skillId?: string,
  name?: string
}
export const getFreelancers = async (params?: TGetFreelancerParams) =>
  (await axios.get("/api/admin/Candidates", { params }))
    .data as IFreelancersRes[];

export const getFreelancerDetail = async (candidateId: string) =>
  (await axios.get(`/api/Candidate/GetFullProfile?candidateId=${candidateId}`))
    .data as ICandidateProfileDetailData;
