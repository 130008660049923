import styled from "styled-components";

export const SIDEBAR_WIDTH = 290;
export const HEADER_HEIGHT = 80;

export const SidebarLayoutWrap = styled.div`
  position: relative;
`;

export const Sidebar = styled.div`
  width: ${SIDEBAR_WIDTH}px;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  border-right: 0.8px solid #bcbcbc;
  scrollbar-width: 0;
  position: fixed;
  left: 0;
  top: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  header {
    height: ${HEADER_HEIGHT}px;
    background-color: #fff;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 0.8px solid #bcbcbc;
    padding-left: 42px;
    z-index: 99;
  }

  .sidebar-menu {
    padding: 20px 18px 0 18px;
  }
`;

export const SidebarContent = styled.div`
  position: relative;
  margin-left: ${SIDEBAR_WIDTH}px;
  min-height: 100vh;
  background-color: #ebf7fd;

  @media screen and (max-width: 768px) {
    margin-left: 0;
  }

  header {
    height: ${HEADER_HEIGHT}px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 0.8px solid #bcbcbc;
    position: fixed;
    left: 290px;
    width: calc(100% - ${SIDEBAR_WIDTH}px);
    top: 0;
    padding: 0 50px;
    z-index: 99;

    @media screen and (max-width: 768px) {
      width: 100%;
      left: 0;
    }
    .header-right {
      display: flex;
      align-items: center;
      gap: 30px;
    }
  }

  .content-container {
    padding: calc(24px + ${HEADER_HEIGHT}px) 50px 0 50px;
  }
  .hamburger {
    background-color: transparent;
    padding: 0;
    outline: none;
    border: none;
    cursor: pointer;

    svg {
      width: 18px;
    }

    @media screen and (min-width: 768px) {
      display: none;
    }
  }
`;
