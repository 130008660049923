import IconChevronDown from "components/Icon/ChevronDown";
import IconGlobe from "components/Icon/Globe";
import { cookieNames } from "constants/cookieNames";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LangSwitcherWrap } from "./style";

export const LOCALES = [
  {
    slug: "en",
    code: "en",
    name: "English",
  },
  {
    slug: "ja",
    code: "ja",
    name: "Japanese (日本語)",
  },
];

export const DEFAULT_LANGUAGE = LOCALES[0].code;

const LanguageSwitcher = () => {
  const [lang, setlang] = useState(() => Cookies.get(cookieNames.lang) || DEFAULT_LANGUAGE);
  const { i18n } = useTranslation("translation");

  const changeLang = (code: string) => {
    i18n.changeLanguage(code);
    Cookies.set(cookieNames.lang, code);
  };

  useEffect(() => {
    setlang(i18n.language);
  }, [i18n.language]);

  return (
    <div>
      <LangSwitcherWrap>
        <div className="flex">
          <IconGlobe />
          {LOCALES.find((item) => item.code === lang)?.name} <IconChevronDown />
        </div>
        <div className="lang-dropdown">
          <ul>
            {LOCALES.map((item) => (
              <li key={item.code} onClick={() => changeLang(item.code)}>
                {item.name}
              </li>
            ))}
          </ul>
        </div>
      </LangSwitcherWrap>
    </div>
  );
};

export default LanguageSwitcher;
