import styled from "styled-components";

interface ILoader extends ILoaderWrapProps {
  variant?: "overlay" | "contained";
}

const Loader = (props: ILoader) => {
  const { size, variant = "contained", backgroundColor } = props;

  return (
    <LoaderWrap size={size} className={`${variant}`} backgroundColor={backgroundColor}>
      <div className="loader">
        <div className="loader__status"></div>
      </div>
    </LoaderWrap>
  );
};

interface ILoaderWrapProps {
  size?: number;
  backgroundColor?: string;
  color1?: string;
  color2?: string;
}

const LoaderWrap = styled.div<ILoaderWrapProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundColor }) => (backgroundColor ? `${backgroundColor}` : "#003e59")};
  width: 100%;
  height: 100%;

  &.contained {
  }

  &.overlay {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
  }

  .loader {
    width: ${({ size }) => (size ? `clamp(1rem, 50vw, ${size}rem)` : "clamp(1rem, 50vw, 3rem)")};
    overflow: hidden;
    animation: rotate 1s linear infinite;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: ${({ color1 }) => (color1 ? `${color1}` : "#2e92bd")};
    position: relative;
  }

  .loader::before {
    z-index: 400;
    position: absolute;
    content: "";
    inset: 10%;
    border-radius: inherit;
    background-color: ${({ backgroundColor }) => (backgroundColor ? `${backgroundColor}` : "#003e59")};
  }

  .loader__status {
    position: absolute;
    inset: 0;
  }

  .loader__status::before {
    position: absolute;
    content: "";
    top: -50%;
    width: 100%;
    height: 100%;
    rotate: 45deg;
    background-color: ${({ color2 }) => (color2 ? `${color2}` : "#6abf40")};
  }

  @keyframes rotate {
    to {
      rotate: 360deg;
    }
  }
`;

export default Loader;
