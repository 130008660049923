import axios from "axios";

export const consultantVerify = async (id: string) =>
  (await axios.post(`/${id}/Verify`)).data;

type TConsultantFilterParams = {
  pendings?: boolean;
  isAscending?: boolean;
  keyword?: string;
};

export interface IGetConsultantFilterRes {
  consultantId: string;
  currentStateId: number;
  email: string;
  location: string;
  name: string;
  phone: string;
}

export interface IConsultantProfileData {
  consultantId: string;
  name: string;
  phone: string;
  country: string;
  state: string;
  city: string;
  otherAddress: string;
  houseNumber: string;
}

export const getAllConsultantWithFilter = async (
  params: TConsultantFilterParams
) =>
  (await axios.get(`/api/admin/Consultants`, { params }))
    .data as IGetConsultantFilterRes[];

export const activateConsultant = async (id: string) =>
  (await axios.put(`/api/admin/Consultants/Activate?id=${id}`)).data;

export const deactivateConsultant = async (id: string) =>
  (await axios.put(`/api/admin/Consultants/Deactivate?id=${id}`)).data;

export const getConsultantDetail = async (consultantId: string) =>
  (
    await axios.get(
      `/api/Consultant/GetFullProfile?consultantId=${consultantId}`
    )
  ).data as IConsultantProfileData;
