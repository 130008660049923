import styled from "styled-components";

export const FPContainer = styled.div`
  min-height: calc(100vh - 151px);
  background: #003e59;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
  padding-top: 50px;
`;

export const FpBox = styled.div`
  background: #ffffff;
  min-width: 320px;
  max-width: 468px;
  padding: 40px;
  text-align: left;

  @media screen and (min-width: 576px) {
    min-width: 468px;
  }
`;
