const IconBriefCase = () => {
    return (
      <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.805 3.70557H17.3819V2.83057C17.379 2.13526 17.0985 1.46925 16.6014 0.977592C16.1043 0.485932 15.431 0.208446 14.7281 0.205566H9.42037C8.71742 0.208446 8.0441 0.485932 7.54704 0.977592C7.04997 1.46925 6.76944 2.13526 6.76653 2.83057V3.70557H2.34345C1.87422 3.70557 1.42421 3.88994 1.09241 4.21813C0.760619 4.54632 0.574219 4.99144 0.574219 5.45557V19.4556C0.574219 19.9197 0.760619 20.3648 1.09241 20.693C1.42421 21.0212 1.87422 21.2056 2.34345 21.2056H21.805C22.2742 21.2056 22.7242 21.0212 23.056 20.693C23.3878 20.3648 23.5742 19.9197 23.5742 19.4556V5.45557C23.5742 4.99144 23.3878 4.54632 23.056 4.21813C22.7242 3.88994 22.2742 3.70557 21.805 3.70557ZM8.53576 2.83057C8.53576 2.5985 8.62896 2.37594 8.79486 2.21185C8.96075 2.04775 9.18576 1.95557 9.42037 1.95557H14.7281C14.9627 1.95557 15.1877 2.04775 15.3536 2.21185C15.5195 2.37594 15.6127 2.5985 15.6127 2.83057V3.70557H8.53576V2.83057ZM21.805 5.45557V10.0056C18.82 11.6153 15.474 12.4577 12.0742 12.4556C8.6744 12.4577 5.32848 11.6153 2.34345 10.0056V5.45557H21.805ZM9.86268 9.83057C9.86268 9.5985 9.95588 9.37594 10.1218 9.21185C10.2877 9.04775 10.5127 8.95557 10.7473 8.95557H13.4011C13.6358 8.95557 13.8608 9.04775 14.0267 9.21185C14.1926 9.37594 14.2858 9.5985 14.2858 9.83057C14.2858 10.0626 14.1926 10.2852 14.0267 10.4493C13.8608 10.6134 13.6358 10.7056 13.4011 10.7056H10.7473C10.5127 10.7056 10.2877 10.6134 10.1218 10.4493C9.95588 10.2852 9.86268 10.0626 9.86268 9.83057Z"
          fill="currentColor"
        />
      </svg>
    );
  };

export default IconBriefCase;