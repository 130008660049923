import React from "react";

const IconUser = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.4068 16.347C19.3356 15.1864 18.0356 14.2601 16.5888 13.6266C15.142 12.9931 13.5796 12.666 12.0002 12.666C10.4207 12.666 8.85837 12.9931 7.41153 13.6266C5.9647 14.2601 4.66473 15.1864 3.5935 16.347C3.42811 16.5299 3.33559 16.7672 3.3335 17.0137V21.0137C3.337 21.2766 3.4439 21.5276 3.63106 21.7122C3.81822 21.8969 4.07057 22.0004 4.3335 22.0004H19.6668C19.932 22.0004 20.1864 21.895 20.3739 21.7075C20.5615 21.52 20.6668 21.2656 20.6668 21.0004V17.0004C20.6615 16.7584 20.5692 16.5265 20.4068 16.347Z"
        fill="currentColor"
      />
      <path
        d="M12.0002 11.3333C14.5775 11.3333 16.6668 9.244 16.6668 6.66667C16.6668 4.08934 14.5775 2 12.0002 2C9.42283 2 7.3335 4.08934 7.3335 6.66667C7.3335 9.244 9.42283 11.3333 12.0002 11.3333Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconUser;
