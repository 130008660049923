import React from "react";

const IconClock = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 0C4.95 0 0 4.95 0 11C0 17.05 4.95 22 11 22C17.05 22 22 17.05 22 11C22 4.95 17.05 0 11 0ZM16.5 12.1H9.9V5.5H11.55V10.45H16.5V12.1Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconClock;
