import styled from "styled-components";

const H1 = styled.h1`
  font-weight: 700;
  font-size: 28px;
  line-height: 45px;
  color: #17202a;
  margin-bottom: 0;
`;

type TMainTitleProps = {
  title: string;
};

const MainTitle = ({ title }: TMainTitleProps) => {
  return <H1>{title}</H1>;
};

export default MainTitle;
