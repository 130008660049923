import React from "react";

const IconSearch = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_974_1637)">
        <path
          d="M9.07184 2.49199C10.2752 2.49089 11.4519 2.84447 12.4531 3.508C13.4542 4.17152 14.2348 5.11518 14.6961 6.21958C15.1573 7.32399 15.2786 8.53953 15.0445 9.71243C14.8104 10.8853 14.2315 11.9629 13.3809 12.8088C12.5304 13.6547 11.4464 14.2309 10.2662 14.4646C9.08603 14.6983 7.86259 14.5789 6.75068 14.1216C5.63878 13.6642 4.68836 12.8895 4.01967 11.8953C3.35098 10.9012 2.99406 9.73231 2.99406 8.53657C3.00135 6.93664 3.64385 5.40421 4.78192 4.27236C5.91999 3.14051 7.46165 2.5007 9.07184 2.49199ZM9.07184 1.36035C7.64341 1.36035 6.24707 1.78123 5.05938 2.56976C3.87169 3.3583 2.946 4.47907 2.39937 5.79035C1.85274 7.10163 1.70971 8.54453 1.98839 9.93658C2.26706 11.3286 2.95491 12.6073 3.96495 13.6109C4.975 14.6145 6.26188 15.298 7.66285 15.5749C9.06382 15.8518 10.516 15.7097 11.8357 15.1665C13.1553 14.6234 14.2833 13.7036 15.0769 12.5235C15.8705 11.3433 16.2941 9.9559 16.2941 8.53657C16.2941 6.63332 15.5331 4.80802 14.1787 3.46222C12.8243 2.11642 10.9873 1.36035 9.07184 1.36035Z"
          fill="currentColor"
        />
        <path
          d="M19.4439 18.0813L15.3494 13.9854L14.5605 14.7637L18.655 18.8597C18.7064 18.9111 18.7676 18.952 18.8349 18.98C18.9022 19.008 18.9745 19.0226 19.0475 19.0228C19.1205 19.0231 19.1928 19.009 19.2603 18.9815C19.3279 18.954 19.3893 18.9135 19.4411 18.8624C19.4929 18.8113 19.5341 18.7506 19.5622 18.6837C19.5904 18.6167 19.605 18.545 19.6053 18.4724C19.6056 18.3999 19.5914 18.328 19.5637 18.2609C19.536 18.1938 19.4953 18.1328 19.4439 18.0813Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_974_1637">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconSearch;
