import { Card, Row, Col, Select, Input, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import {
  getAllConsultantWithFilter,
  IGetConsultantFilterRes,
} from "api/consultant";
import DescriptionText from "components/DescriptionText";
import { GhostBtn } from "components/GhostBtn";
import IconCaretDown from "components/Icon/CaretDown";
import IconEdit from "components/Icon/Edit";
import IconSearch from "components/Icon/Search";
import MainTitle from "components/MainTitle";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import ApprovedRequestAction from "./ApprovedRequestAction";
import moment from "moment";

const ApprovedConsultants = () => {
  const { t } = useTranslation("translation");
  const [visible, setvisible] = useState(false);
  const [selected, setselected] = useState<IGetConsultantFilterRes>({
    consultantId: "",
    currentStateId: 0,
    email: "",
    location: "",
    name: "",
    phone: "",
  });
  const [isAscending, setisAscending] = useState<string>("undefined");
  const [keyword, setkeyword] = useState<string>("");

  const { data, mutate } = useMutation(
    "get-all-pending-consultant",
    getAllConsultantWithFilter
  );

  const PendingRequestColumns: ColumnsType<any> = [
    {
      key: "Consultancy Name",
      title: t("Consultancy_Name"),
      dataIndex: "name",
      render: (_, row) => {
        return (
          <Link
            to={`/approved-consultants/${row?.consultantId}`}
            style={{ color: "#cc00ff" }}>
            {row?.name}
          </Link>
        );
      },
    },
    {
      key: "Email",
      title: t("Email_email"),
      render: (_,row)=>{
        return(
          <a href={'mailto:' + row?.email}>{row?.email}</a>
        )
      },
    },
    {
      key: "Location",
      title: t("Location_location__2"),
      dataIndex: "location",
    },
    {
      key: "Phone",
      title: t("Phone_phone"),
      dataIndex: "phone",
    },
    {
      key: "Created Date",
      title: t("Created_Date"),
      dataIndex: "createdAt",
      render: (text) => moment.utc(text).local().format("YYYY-MM-DD"),
    },
    {
      key: "Approved Date",
      title: t("Approved_Date"),
      dataIndex: "approvedAt",
      render: (text) => moment.utc(text).local().format("YYYY-MM-DD"),
    },
    {
      key: "Action",
      title: t("Action_action"),
      render: (record) => (
        <GhostBtn
          onClick={() => {
            setvisible(true);
            setselected(record);
          }}>
          <IconEdit />
        </GhostBtn>
      ),
    },
  ];

  const handleVerified = () => {
    mutate({
      isAscending: getAscendingValue(isAscending),
      keyword,
      pendings: false,
    });
  };

  const getAscendingValue = (value: string) => {
    if (value === "undefined") return undefined;
    if (value === "true") return true;
    if (value === "false") return false;
  };

  const onSearch = debounce((ev) => setkeyword(ev.target.value), 550);

  useEffect(() => {
    mutate({
      isAscending: getAscendingValue(isAscending),
      keyword,
      pendings: false,
    });
    // eslint-disable-next-line
  }, [isAscending, keyword]);

  useEffect(() => {
    mutate({ pendings: false });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <MainTitle title={t("Consultant_Approved_Requests")} />
      <DescriptionText text={t("View_Approved_Request")} />
      <br />
      <br />
      <Card>
        <Row align="middle" gutter={12}>
          <Col>{t("Sort_by")}:</Col>
          <Col>
            <Select
              style={{ width: 120 }}
              defaultValue={isAscending}
              suffixIcon={<IconCaretDown />}
              onChange={(val: any) => setisAscending(val)}>
              <Select.Option value={"undefined"}>{t("Default")}</Select.Option>
              <Select.Option value={"true"}>{t("Ascending")}</Select.Option>
              <Select.Option value={"false"}>{t("Descending")}</Select.Option>
            </Select>
          </Col>
          <Col>
            <Input
              prefix={<IconSearch />}
              placeholder={t("search_search")}
              onChange={onSearch}
            />
          </Col>
        </Row>
        <br />
        <Table
          rowKey={(prop) => prop.id}
          pagination={{
            pageSize: 10,
          }}
          columns={PendingRequestColumns}
          dataSource={data || []}
          scroll={{ x: 1200 }}
        />
      </Card>
      <ApprovedRequestAction
        visible={visible}
        onCancel={() => setvisible(false)}
        selected={selected}
        handleVerified={handleVerified}
      />
    </>
  );
};

export default ApprovedConsultants;
