import axios from "axios";

export interface IGetAdminJobFavoriteRes {
  id: string;
  candidateName: string;
  jobTitle: string;
  jobProvider: string;
  favoritedAt: Date;
}

export interface IJobConfigRes {
  id: string;
  isActive: boolean;
  isCompanyNameVisible: boolean;
  isCompanyEmailVisible: boolean;
  isPublicAddressVisible: boolean;
  isPrivateAddressVisible: boolean;
}

type IGetAdminJobFavoriteParams = {
  sortOrder?: string;
  candidateNameFilter?: string;
};

export const getAdminJobFavorite = async (params: IGetAdminJobFavoriteParams) => {
  return (await axios.get(`/api/admin/Favorites/Jobs`, { params }))
    .data as IGetAdminJobFavoriteRes[];
};

export const getAdminJobConfig = async (userId: string) => {
  return (await axios.get(`/api/admin/Favorites/GetJobConfig?id=${userId}`))
    .data as IJobConfigRes;
};

export const updateJobConfig = async (data: IJobConfigRes) =>
  (await axios.put("/api/admin/Favorites/UpdateJobConfig", data)).data;
interface IRequiredLanguageLevel {
  language: string | null;
  level: string | null;
}
export interface ICandidateJobFavoriteDetail {
  id: string;
  title: string | null;
  description: string | null;
  amount: number | null;
  paymentTypeId: number | null;
  jobTypeId: number | null;
  publicAddress: string | null;
  privateAddress: string | null;
  companyName: string | null;
  companyEmail: string | null;
  requiredExperienceLevel: number | null;
  skills: string[] | null;
  motherTongue: string | null;
  requiredLanguageLevels: IRequiredLanguageLevel[] | null;
}
export const getCandidateFavoriteJobDetail = async (jobId: string) =>
  (await axios.get(`/api/Favorites/JobById?id=${jobId}`))
    .data as ICandidateJobFavoriteDetail;

export interface IGetAdminJobsCreated {
  companyName: string;
  companyEmail: string;
  jobTitle: string;
  createdAt: Date;
}

type TGetJobsCreatedParams = {
  sortOrder?: string;
  keyword?: string;
};

export const getAllJobsCreated = async (params: TGetJobsCreatedParams) =>
  (await axios.get(`/api/admin/Jobs/GetAllJobs`, { params }))
    .data as IGetAdminJobsCreated[];

export interface IJobCreatedDetail {
  id: string;
  title: string | null;
  description: string | null;
  paymentAmount: number | null;
  paymentType: string | null;
  jobType: string | null;
  publicAddress: string | null;
  tag: string | null;
  state: string | null;
  city: string | null;
  country: string | null;
  company: jobCreatedCompanyDetail | null;
  requiredExperienceLevel: string | null;
  skills: string[] | null;
  motherTongue: string | null;
  languageLevels: IRequiredLanguageLevel[] | null;
}

export interface jobCreatedCompanyDetail {
  name: string | null;
  email: string | null;
}


export const getJobCreatedDetail = async (jobId: string) => 
  (await axios.get(`/api/admin/Jobs/GetJobById?jobId=${jobId}`))
    .data as IJobCreatedDetail;