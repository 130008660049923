import { Form, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import { editAdmin, IGetAdminsRes } from "api/admin";
import { ConfigCardTitle } from "components/ConfigCard";
import { StyledButton } from "components/StyledButton";
import { StyledInput } from "components/StyledInput";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

interface ICompaniesActionProps {
  actionRecord?: IGetAdminsRes;
  handleSuccess: () => void;
  visible: boolean;
  onCancel: () => void;
}

const CompaniesAction = ({
  visible,
  onCancel,
  actionRecord,
  handleSuccess,
}: ICompaniesActionProps) => {
  const { t } = useTranslation("translation");
  const { mutate, isLoading } = useMutation(editAdmin, {
    onSuccess: handleSuccess,
  });
  const [form] = useForm();

  const handleFinish = (values: any) => {
    mutate({
      ...values,
      id: actionRecord?.id,
    });
  };

  useEffect(() => {
    if (actionRecord) {
      form.setFieldsValue(actionRecord);
    }
    // eslint-disable-next-line
  }, [actionRecord]);

  return (
    <Modal
      destroyOnClose
      onCancel={onCancel}
      visible={visible}
      footer={null}
      title={null}>
      <ConfigCardTitle>
        {actionRecord?.firstName + " " + actionRecord?.lastName}
      </ConfigCardTitle>
      <Form labelCol={{ span: 24 }} form={form} onFinish={handleFinish}>
        <Form.Item name="firstName" label={t("FirstName")}>
          <StyledInput />
        </Form.Item>
        <Form.Item name="lastName" label={t("LastName")}>
          <StyledInput />
        </Form.Item>
        <Form.Item>
          <StyledButton loading={isLoading} htmlType="submit">
            {t("Submit_submit")}
          </StyledButton>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default React.memo(CompaniesAction);
