import IconApprovedDoc from "components/Icon/ApprovedDoc";
import ApprovedUser from "components/Icon/ApprovedUser";
import IconBuilding from "components/Icon/Building";
import IconGroupUser from "components/Icon/GroupUser";
import IconHeart from "components/Icon/Heart";
import IconClock from "components/Icon/IconClock";
import IconUserPlus from "components/Icon/UserPlus";
import IconBriefCase from "components/Icon/IconBriefCase";

export const MENU = [
  {
    id: "/freelancers",
    path: "/freelancers",
    Icon: IconGroupUser,
    name: "Freelancer_freelancer",
  },
  {
    id: "/companies",
    path: "/companies",
    Icon: IconBuilding,
    name: "companies_companies",
  },
  {
    id: "jobs-created",
    path: "/jobs-created",
    Icon: IconBuilding,
    name: "jobs_created"
  },
  {
    id: "/company-favourite",
    path: "/company-favourite",
    Icon: IconHeart,
    name: "company_favourite",
  },
  {
    id: "/job-favourite",
    path: "/job-favourite",
    Icon: IconHeart,
    name: "job_favourite",
  },
  {
    id: "/pending-requests",
    path: "/pending-requests",
    Icon: IconClock,
    name: "Pending_Requests",
  },
  // {
  //   id: "/configurations",
  //   path: "/configurations",
  //   Icon: IconUserSettings,
  //   name: "Configurations",
  // },
  {
    id: "/approved-consultants",
    path: "/approved-consultants",
    Icon: IconApprovedDoc,
    name: "Consultants",
  },
];

export const SUPER_MENU = [
  {
    id: "/freelancers",
    path: "/freelancers",
    Icon: IconGroupUser,
    name: "Freelancer_freelancer",
  },
  {
    id: "/companies",
    path: "/companies",
    Icon: IconBuilding,
    name: "companies_companies",
  },
  {
    id: "jobs-created",
    path: "/jobs-created",
    Icon: IconBriefCase,
    name: "jobs_created"
  },
  {
    id: "/company-favourite",
    path: "/company-favourite",
    Icon: IconHeart,
    name: "company_favourite",
  },
  {
    id: "/job-favourite",
    path: "/job-favourite",
    Icon: IconHeart,
    name: "job_favourite",
  },
  {
    id: "/pending-requests",
    path: "/pending-requests",
    Icon: IconClock,
    name: "Pending_Requests",
  },
  {
    id: "/approved-consultants",
    path: "/approved-consultants",
    Icon: IconApprovedDoc,
    name: "Consultants",
  },
  {
    id: "/register-admin",
    path: "/register-admin",
    Icon: IconUserPlus,
    name: "Register_Admin",
  },
  {
    id: "/registered-admin",
    path: "/registered-admin",
    Icon: ApprovedUser,
    name: "Registered_Admin",
  },
];
