import { Card, Row, Col, Select, Input, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { getAllJobsCreated } from "api/admin/job";
import DescriptionText from "components/DescriptionText";
import { GhostBtn } from "components/GhostBtn";
import IconCaretDown from "components/Icon/CaretDown";
import IconEdit from "components/Icon/Edit";
import IconSearch from "components/Icon/Search";
import MainTitle from "components/MainTitle";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import moment from "moment";

import React from 'react'

const JobsCreated = () => {

    const { t } = useTranslation("translation");
    const [isAscending, setisAscending] = useState<string>("undefined");
    const [keyword, setkeyword] = useState<string>("");

    const { data, mutate } = useMutation("get-jobsCreated", getAllJobsCreated);

    const jobsCreatedTableColumns: ColumnsType<any> = [
        {
            key: "Companies Name",
            title: t("Companies_name"),
            dataIndex: "companyName",
            render: (text) => text,
        },
        {
            key: "Companies Email",
            title: `${t("companie_companie")} ${t("Email_email")}`,
            dataIndex: "companyEmail",
            render: (_,row)=>{
                return(
                    <a href={'mailto:' + row?.companyEmail}>{row?.companyEmail}</a>
                )
            },
        },
        {
            key: "Job Title",
            title: t("jobTitle"),
            dataIndex: "jobTitle",
            render: (_, row) => {
                return (
                    <Link to={`/jobs-created/${row?.jobId}`} style={{ color: "#cc00ff" }}>
                        {row?.jobTitle}
                    </Link>
                );
            },
        },
        {
            key: "Created Date",
            title: t("Created_Date"),
            dataIndex: "createdAt",
            render: (text) => moment.utc(text).local().format("YYYY-MM-DD"),
        },
    ];
    
    const getAscendingValue = (value: string) => {
        if (value === "undefined") return "desc";
        if (value === "true") return "asc";
        if (value === "false") return "desc";
    };

    const onSearch = debounce((ev) => setkeyword(ev.target.value), 550);

    useEffect(() => {
        const sortValue = getAscendingValue(isAscending);
        mutate({ sortOrder: sortValue, keyword: keyword });
        // eslint-disable-next-line
    }, [isAscending, keyword]);

    return (
        <>
        <MainTitle title={t("jobs_created")} />
        <DescriptionText text={t("All_here_jobs_created")} />
        <br />
        <br />
        <Card>
            <Row align="middle" gutter={12}>
            <Col>{t("Sort_by")}:</Col>
            <Col>
                <Select
                style={{ width: 120 }}
                defaultValue={isAscending}
                suffixIcon={<IconCaretDown />}
                onChange={(val: any) => setisAscending(val)}>
                <Select.Option value={"undefined"}>{t("Default")}</Select.Option>
                <Select.Option value={"true"}>{t("Ascending")}</Select.Option>
                <Select.Option value={"false"}>{t("Descending")}</Select.Option>
                </Select>
            </Col>
            <Col>
                <Input
                prefix={<IconSearch />}
                placeholder={t("search_search")}
                onChange={onSearch}
                />
            </Col>
            </Row>
            <br />
            <Table
            rowKey={(prop) => prop.id}
            pagination={{
                pageSize: 10,
            }}
            columns={jobsCreatedTableColumns}
            dataSource={data}
            scroll={{ x: 1200 }}
            />
        </Card>
        </>
    )
}

export default React.memo(JobsCreated)