import React from "react";

const IconLogout = () => {
  return (
    <svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.83333 0.333008H15.5C17.4833 0.333008 19 1.84967 19 3.83301V10.833H8.96667L11.65 8.14967C12.1167 7.68301 12.1167 6.98301 11.65 6.51634C11.1833 6.04967 10.4833 6.04967 10.0167 6.51634L5.35 11.183C4.88333 11.6497 4.88333 12.3497 5.35 12.8163L10.0167 17.483C10.4833 17.9497 11.1833 17.9497 11.65 17.483C12.1167 17.0163 12.1167 16.3163 11.65 15.8497L8.96667 13.1663H19V20.1663C19 22.1497 17.4833 23.6663 15.5 23.6663H3.83333C1.85 23.6663 0.333334 22.1497 0.333334 20.1663V3.83301C0.333334 1.84967 1.85 0.333008 3.83333 0.333008Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconLogout;
