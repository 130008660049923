import axios from "axios";

type TCompanyUpdateData = {
  isNameVisible: boolean;
  isCountryVisible: boolean;
  isStateVisible: boolean;
  isCityVisible: boolean;
  favoriteCountLimit: number;
  favoritePeriodLimit: number;
  configurationId: string;
};

export const updateCompanyConfig = async (data: TCompanyUpdateData) =>
  (await axios.put("/api/admin/Companies/UpdateConfiguration", data)).data;
