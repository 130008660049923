import { routes } from "constants/routes";
import ApprovedConsultants from "pages/ApprovedConsultants";
import Companies from "pages/Companies";
import CompanyFavourite from "pages/CompanyFavourite";
// import Configurations from "pages/Configurations";
import Freelancers from "pages/Freelancers";
import JobsCreated from "pages/JobCreated";
import PendingRequest from "pages/PendingRequest";
import RegisterAdmin from "pages/RegisterAdmin";
import RegisteredAdmin from "pages/RegisteredAdmin";
import JobFavorite from "pages/JobFavourite";
import CompanyDetail from "pages/Companies/details";
import ConsultantDetail from "pages/ApprovedConsultants/detail";
import FreelancerDetail from "pages/Freelancers/detail";
import CompanyFavoriteDetail from "pages/CompanyFavourite/detail";
import JobFavoriteDetail from "pages/JobFavourite/detail";
import JobCreatedDetail from "pages/JobCreated/detail";
import PendingRequestConsultantDetail from "pages/PendingRequest/detail";

export const ADMIN_ROUTES = [
  {
    key: "/freelancers",
    path: routes.freelancers,
    Element: Freelancers,
  },
  {
    key: "/freelancers/:id",
    path: routes.freelancerDetail,
    Element: FreelancerDetail,
  },
  {
    key: "/companies",
    path: routes.companies,
    Element: Companies,
  },
  {
    key: "/companies/:id",
    path: routes.companyDetail,
    Element: CompanyDetail,
  },
  {
    key: "/company-favourite",
    path: routes.companyFavorite,
    Element: CompanyFavourite,
  },
  {
    key: "/company-favourite/:id",
    path: routes.companyFavoriteDetail,
    Element: CompanyFavoriteDetail,
  },
  {
    key: "/job-favourite",
    path: routes.jobFavorite,
    Element: JobFavorite,
  },
  {
    key: "/job-favourite/:id",
    path: routes.jobFavoriteDetail,
    Element: JobFavoriteDetail,
  },
  {
    key: "/pending-requests",
    path: routes.pendingRequests,
    Element: PendingRequest,
  },
  {
    key: "/pending-requests/:id",
    path: routes.pendingRequestsDetail,
    Element: PendingRequestConsultantDetail,
  },
  // {
  //   key: "/configurations",
  //   path: routes.freelancers,
  //   Element: Configurations,
  // },
  {
    key: "/approved-consultants",
    path: routes.approvedConsultants,
    Element: ApprovedConsultants,
  },
  {
    key: "/approved-consultants/:id",
    path: routes.consultantDetail,
    Element: ConsultantDetail,
  },
  {
    key: "/register-admin",
    path: routes.registerAdmin,
    Element: RegisterAdmin,
  },
  {
    key: "/registered-admin",
    path: routes.registeredAdmin,
    Element: RegisteredAdmin,
  },
  {
    key: "/jobs-created",
    path: routes.jobsCreated,
    Element: JobsCreated,
  },
  {
    key: "/jobs-created/:id",
    path: routes.jobsCreatedDetail,
    Element: JobCreatedDetail,
  },
];
