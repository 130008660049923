export const colors = {
   primary: "#009BDE",
   primaryTint: "#F3FAFE",
   secondary: "#00B674",
   secondaryTint: "#E6F8F1",
   background: "#FDFEFF",
   white: "#FFFFFF",
   text: "#17202A",
   grey: "#828282",
   lightGrey: "#D9D9D9",
   lightGrey2: "#F3F3F3",
   stroke: "#BCBCBC",
   error: "#F62E2E",
   errorTint: "#FEEAEA",
};
