import IconLogout from "components/Icon/Logout";
import { getUserInfo } from "helpers/authCookies";
import { AuthContext } from "provider/AuthProvider";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MENU, SUPER_MENU } from "./menu";
import { SidebarMenuItem } from "./style";
import { useTranslation } from "react-i18next";

const SidebarMenu = () => {
  const { t } = useTranslation("translation");
  const location = useLocation();
  const navigate = useNavigate();
  const { logOut } = useContext(AuthContext);

  const userInfo = getUserInfo();
  const navigateToMenuPath = (path: string) => {
    navigate({
      pathname: path,
    });
  };

  return (
    <>
      {(userInfo?.role as string) === "Admin"
        ? MENU.map((item, index) => (
            <>
              <SidebarMenuItem
                key={index}
                className={
                  location.pathname.includes(item.path) ? "active" : ""
                }
                onClick={() => navigateToMenuPath(item.path)}>
                <div className="icon-wrap">
                  <item.Icon />
                </div>
                <div className="menu-name">{t(item.name)}</div>
              </SidebarMenuItem>
            </>
          ))
        : SUPER_MENU.map((item, index) => (
            <>
              <SidebarMenuItem
                key={index}
                className={
                  location.pathname.includes(item.path) ? "active" : ""
                }
                onClick={() => navigateToMenuPath(item.path)}>
                <div className="icon-wrap">
                  <item.Icon />
                </div>
                <div className="menu-name">{t(item.name)}</div>
              </SidebarMenuItem>
            </>
          ))}
      <SidebarMenuItem key={50} onClick={logOut}>
        <div className="icon-wrap">
          <IconLogout />
        </div>
        <div className="menu-name">{t("Logout")}</div>
      </SidebarMenuItem>
    </>
  );
};

export default SidebarMenu;
