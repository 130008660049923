import { Card, Row, Col, Select, Input, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { getAdminJobFavorite } from "api/admin/job";
import DescriptionText from "components/DescriptionText";
import { GhostBtn } from "components/GhostBtn";
import IconEdit from "components/Icon/Edit";
import MainTitle from "components/MainTitle";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import IconCaretDown from "components/Icon/CaretDown";
import IconSearch from "components/Icon/Search";
const JobFavoriteAction = React.lazy(() => import("./JobFavoriteAction"));

const JobFavorite = () => {
  const { t } = useTranslation("translation");
  const [id, setId] = useState<string>();
  const [visible, setvisible] = useState(false);
  const [isAscending, setisAscending] = useState<string>("undefined");
  const [keyword, setkeyword] = useState<string>("");

  const { data, mutate } = useMutation("get-job-favorite", getAdminJobFavorite);

  const getAscendingValue = (value: string) => {
    if (value === "undefined") return "desc";
    if (value === "true") return "asc";
    if (value === "false") return "desc";
  };

  const onSearch = debounce((ev) => setkeyword(ev.target.value), 550);

  useEffect(() => {
    const sortValue = getAscendingValue(isAscending);
    mutate({ sortOrder: sortValue, candidateNameFilter: keyword });
    // eslint-disable-next-line
  }, [isAscending, keyword]);

  const handleSuccess = () => {
    const sortValue = getAscendingValue(isAscending);
    mutate({ sortOrder: sortValue, candidateNameFilter: keyword });
    setvisible(false);
  };

  const companiesColumns: ColumnsType<any> = [
    {
      key: "Candidate Name",
      title: t("Candidate_name"),
      dataIndex: "candidateName",
      render: (text) => text,
    },
    {
      key: "Job Title",
      title: t("jobTitle"),
      dataIndex: "jobTitle",
      render: (_, row) => {
        return (
          <Link to={`/job-favourite/${row?.id}`} style={{ color: "#cc00ff" }}>
            {row?.jobTitle}
          </Link>
        );
      },
    },
    {
      key: "Job Provider",
      title: t("jobProvider"),
      dataIndex: "jobProvider",
      render: (text) => text,
    },
    {
      key: "Favourite Date",
      title: t("Favourite_date"),
      dataIndex: "favoritedAt",
      render: (text) => moment.utc(text).local().format("YYYY-MM-DD"),
    },
    {
      key: "Action",
      title: t("Action_action"),
      render: (record) => (
        <GhostBtn
          onClick={() => {
            setvisible(true);
            setId(record?.id);
          }}>
          <IconEdit />
        </GhostBtn>
      ),
    },
  ];

  return (
    <>
      <MainTitle title={t("job_favourite")} />
      <DescriptionText text={t("job_favourite_desc")} />
      <br />
      <br />
      <Card>
        <Row align="middle" gutter={12}>
        <Col>{t("Sort_by")}:</Col>
        <Col>
            <Select
            style={{ width: 120 }}
            defaultValue={isAscending}
            suffixIcon={<IconCaretDown />}
            onChange={(val: any) => setisAscending(val)}>
            <Select.Option value={"undefined"}>{t("Default")}</Select.Option>
            <Select.Option value={"true"}>{t("Ascending")}</Select.Option>
            <Select.Option value={"false"}>{t("Descending")}</Select.Option>
            </Select>
        </Col>
        <Col>
            <Input
            prefix={<IconSearch />}
            placeholder={t("search_search")}
            onChange={onSearch}
            />
        </Col>
        </Row>
        <br />
        <Table
          rowKey={(prop) => prop.id}
          pagination={{
            pageSize: 10,
          }}
          columns={companiesColumns}
          dataSource={data || []}
          scroll={{ x: 1200 }}
        />
      </Card>
      <JobFavoriteAction
        handleSuccess={handleSuccess}
        id={id}
        visible={visible}
        onCancel={() => setvisible(false)}
      />
    </>
  );
};

export default JobFavorite;
