import { getAdminProfile, IGetAdminProfileRes } from "api/admin";
import { UserStatus, UserStatusID } from "constants/userStatus";
import { deleteToken, getToken, getUserInfo, roles } from "helpers/authCookies";
import { setAuthHeaders } from "helpers/axios";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";

export type TUserStatus = keyof typeof UserStatusID;

interface ILoggedInStatus {
  isLoggedIn: boolean;
  userStatus?: TUserStatus;
}

interface IAuthContext extends ILoggedInStatus {
  setLoggedInStatus: (
    isLoggedIn: boolean,
    userStatus?: TUserStatus,
    profile?: IGetAdminProfileRes
  ) => void;
  logOut: () => void;
  profile?: IGetAdminProfileRes;
  role?: string;
}

const defaultContext = {
  isLoggedIn: false,
  userStatus: undefined,
  setLoggedInStatus: () => null,
  logOut: () => null,
  profile: undefined,
};

export const AuthContext = React.createContext<IAuthContext>(defaultContext);

const AuthProvider = ({ children }: any) => {
  const [value, setvalue] = useState<IAuthContext>(defaultContext);

  const { mutateAsync: getProfile } = useMutation(getAdminProfile);

  const setLoggedInStatus = (
    isLoggedIn: boolean,
    userStatus?: TUserStatus,
    profile?: IGetAdminProfileRes
  ) => {
    setvalue((prev) => ({
      ...prev,
      isLoggedIn,
      userStatus,
      profile,
    }));
  };

  const logOut = () => {
    deleteToken();
    setvalue((prev) => ({
      ...prev,
      isLoggedIn: false,
      userStatus: undefined,
      profile: undefined,
    }));
  };

  const init = async () => {
    const token = getToken();
    if (token) {
      setAuthHeaders(token);
      let userInfo = getUserInfo();
      if (userInfo?.role.toLowerCase() === roles.superAdmin.toLowerCase()) {
        setvalue((prev) => ({
          ...prev,
          isLoggedIn: true,
          role: userInfo?.role,
          userStatus: "Active",
          logOut,
          setLoggedInStatus,
        }));
      } else {
        let profile = await getProfile(userInfo?.uid!);

        setvalue((prev) => ({
          ...prev,
          isLoggedIn: true,
          role: userInfo?.role,
          userStatus: UserStatus[
            profile.currentStateId as keyof typeof UserStatus
          ] as TUserStatus,
          profile,
          logOut,
          setLoggedInStatus,
        }));
      }
    } else {
      setvalue((prev) => ({
        ...prev,
        setLoggedInStatus,
        logOut,
      }));
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
