// import IconBell from "components/Icon/Bell";
import LanguageSwitcher from "components/LanguageSwitcher";
import IconHamburger from "Icons/Hamburger";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import Logo from "../../components/Icon/Logo";
import DrawerMenu from "./SidebarMenu/DrawerMenu";
import SidebarMenu from "./SidebarMenu/SidebarMenu";
import { Sidebar, SidebarContent, SidebarLayoutWrap } from "./style";

const SidebarLayout = ({ children }: any) => {
  const isBigScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const [open, setopen] = useState(false);

  return (
    <SidebarLayoutWrap>
      <Sidebar>
        <header>
          <Logo />
        </header>
        <nav className="sidebar-menu">
          <SidebarMenu />
        </nav>
      </Sidebar>
      <SidebarContent>
        <header>
          <button className="hamburger" onClick={() => setopen(true)}>
            <IconHamburger />
          </button>
          <DrawerMenu
            visible={isBigScreen ? open : false}
            closable={false}
            width={300}
            onClose={() => setopen(false)}
          />
          <div />
          <div className="header-right">
            <LanguageSwitcher />
            {/* <IconBell /> */}
            {/* <Avatar style={{ backgroundColor: "#B80084" }} size={32}>
              A
            </Avatar> */}
          </div>
        </header>
        <div className="content-container">{children}</div>
      </SidebarContent>
    </SidebarLayoutWrap>
  );
};

export default React.memo(SidebarLayout);
